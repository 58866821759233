import { Component, OnInit } from '@angular/core';
import {SuperAdminService} from '../../services/apiCalls/superAdminService/super-admin.service';
import {BootstrapNotifyService} from '../../services/bootstrap-notify/bootstrap-notify.service';
import {IResponse} from '../../interfaces/iresponse';
import {UtilService} from '../../services/utilService/util.service';

@Component({
  selector: 'app-vendor-management',
  templateUrl: './vendor-management.component.html',
  styleUrls: ['./vendor-management.component.css']
})
export class VendorManagementComponent implements OnInit {
  breadCrumb: any;
  vendors: any[] = [];
  loaders = {
    loading: false,
    loadingData: false,
    changeOccured: false
  };
  constructor(private superAdminService: SuperAdminService,
              private utilService: UtilService,
              private alertService: BootstrapNotifyService) { }
  ngOnInit() {
    this.breadCrumb  = {
      name: 'Vendor Management',
      parent: 'Users',
      subLink: null
    };
    this.getVendors();
  }
  getVendors() {
    this.loaders.loadingData = true;
    this.loaders.changeOccured = false;
    this.superAdminService.getVendors().subscribe((res: IResponse) => {
      this.loaders.loadingData = false;
      this.loaders.changeOccured = true;
      this.vendors = res.data;
      console.log('RESponse ', res);
      this.utilService.startDatatable('vendors-list');
      this.alertService.success(res.message || 'Vendors listed successfully');
    }, error => {
      console.log('Errors ', error);
      this.alertService.error(error.error.message || 'Unable to list vendors');
      this.utilService.startDatatable('vendors-list');
      this.loaders.loadingData = false;
      this.loaders.changeOccured = true;
    });

  }

  public triggerEdit(vendor) {
    /*this.activeVendor = this.vendor = JSON.parse(JSON.stringify(vendor));
    this.resetForm();
    this.category = this.activeVendor;
    this.modal = {
      title: 'Update Vendor',
      btnText: 'UPDATE'
    };
    this.utilService.openModal('createVendor');*/
  }


}
