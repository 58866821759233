import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-section-management',
  templateUrl: './section-management.component.html',
  styleUrls: ['./section-management.component.css']
})
export class SectionManagementComponent implements OnInit {
  breadCrumb: any;
  constructor() { }

  ngOnInit() {
    this.breadCrumb  = {
      name: 'Section Management',
      parent: 'Home',
      subLink: null
    };
  }

}
