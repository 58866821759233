import { Component, OnInit } from '@angular/core';
import {IResponse} from "../../interfaces/iresponse";
import {UserService} from "../../services/apiCalls/userService/user.service";
import {UtilService} from "../../services/utilService/util.service";
import {BootstrapNotifyService} from "../../services/bootstrap-notify/bootstrap-notify.service";
import {NavigatorService} from "../../services/navigatorService/navigator.service";

@Component({
  selector: 'app-vendor-low-stock-report',
  templateUrl: './vendor-low-stock-report.component.html',
  styleUrls: ['./vendor-low-stock-report.component.css']
})
export class VendorLowStockReportComponent implements OnInit {
  breadCrumb: any;
  orders = [];
  currentUser = null;
  loading = false;
  constructor(private utilService: UtilService,
              private navigatorService: NavigatorService,
              private userService: UserService,
              private alertService: BootstrapNotifyService) { }

  ngOnInit() {
    this.currentUser = this.utilService.getAuthUser();
    this.breadCrumb  = {
      name: 'Low Stock',
      parent: 'Report',
      subLink: null
    };
    this.getOrders();
  }
  getOrders() {
    this.loading = true;
    this.userService.getLowStockOrdersReport(this.currentUser.vendor_id)
      .subscribe((res: IResponse) => {
        this.orders = res.data;
        this.utilService.startDatatable('transaction-list');
        this.alertService.success(res.message || 'Low stock products listed successfully');
        this.loading = false;
      }, error => {
        console.log({error});
        this.loading = false;
        this.utilService.startDatatable('transaction-list');
        this.alertService.error(error.error.message || 'Unable to list low stock products!');
      });
  }
  gotoURL(url) {
    this.navigatorService.navigateUrl(url);
  }



}
