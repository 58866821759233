import { Component, OnInit } from '@angular/core';
import { environment as ENV } from '../../../environments/environment';
import {BootstrapNotifyService} from '../../services/bootstrap-notify/bootstrap-notify.service';
import {UserService} from '../../services/apiCalls/userService/user.service';
import {IResponse} from '../../interfaces/iresponse';
import {NavigatorService} from '../../services/navigatorService/navigator.service';
import * as JWT_DECODE from 'jwt-decode';
import {CacheService} from '../../services/cacheService/cache.service';
import {ActivatedRoute, ActivatedRouteSnapshot, Router} from '@angular/router';
import {AuthService} from '../../services/authService/auth.service';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.css']
})
export class SetPasswordComponent implements OnInit {
  public EMAIL_VALIDATION: any =  ENV.EMAIL_VALIDATION;
  public credentials = {
    password_confirmation: null,
    password: null,
    token: null,
  };
  public userDetails: any;
  loaders = {
    login: false,
    showResetLink: false
  };
  constructor(private bootstrapNotify: BootstrapNotifyService,
              private userService: UserService,
              private navigatorService: NavigatorService,
              private route: ActivatedRoute,
              private authService: AuthService,
              private cacheService: CacheService) {
    this.authService.logOut();
  }
  ngOnInit(): void {
    this.credentials.token = this.route.snapshot.paramMap.get('token');
    // this.route.snapshot.paramMap.get('codeSecret');
    this.userDetails = null;
  }
  public resetPassword() {
    this.loaders.login = true;
    this.cacheService.deleteSession(ENV.TOKEN);
    this.cacheService.deleteStorage(ENV.TOKEN);
    if (!this.credentials.password_confirmation || !this.credentials.password) {
      this.bootstrapNotify.info('Provide New Password!');
      return false;
    } else if (this.credentials.password_confirmation !== this.credentials.password) {
      this.bootstrapNotify.info('Both password must match!');
      return false;
    } else {
      this.userService.resetPassword(this.credentials).subscribe((response: IResponse) => {
        console.log('Response', response);
        this.loaders.login = false;
        this.bootstrapNotify.success(response['message'] || 'Please proceed to login');
        this.navigatorService.navigateUrl('/');
      }, error => {
        this.bootstrapNotify.error(error.error.message || 'Unable to reset password', 'right');
        this.loaders.login = false;
        console.info('Error => ', error);
      });
    }
  }
}
