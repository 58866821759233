import { Component, OnInit } from '@angular/core';
import {IResponse} from "../../../interfaces/iresponse";
import {SuperAdminService} from "../../../services/apiCalls/superAdminService/super-admin.service";
import {UtilService} from "../../../services/utilService/util.service";
import {BootstrapNotifyService} from "../../../services/bootstrap-notify/bootstrap-notify.service";
import {isNullOrUndefined} from "util";

@Component({
  selector: 'app-manage-authors',
  templateUrl: './manage-authors.component.html',
  styleUrls: ['./manage-authors.component.css']
})
export class ManageAuthorsComponent implements OnInit {
  breadCrumb: any;
  manufacturers = [];
  vendors = [];

  loaders = {
    manufacturer: false,
    saving: false
  };
  manufacturerModal = {
    title: 'Create Author',
    btnText: 'SAVE',
  };

  manufacturer = {
    manufacturer_name: null,
    sort_order: Math.floor((Math.random() * 100)),
    vendor_id: null,
    imageupload: null,
    image: null,
  };
  activeManufacturer = null;
  constructor(private superAdminService: SuperAdminService,
              private utilService: UtilService,
              private alertService: BootstrapNotifyService) {
    this.getVendors();
  }

  ngOnInit() {
    this.breadCrumb  = {
      name: 'Manage Authors',
      parent: 'Configuration',
      subLink: null
    };
    this.getManufacturers();
  }

  public getManufacturers() {
    this.loaders.manufacturer = true;
    this.superAdminService.getManufacturers().subscribe((res: IResponse) => {
      console.log('Res ', res);
      this.loaders.manufacturer = false;
      this.manufacturers = res.data;
      this.utilService.responsiveTable('manufacturer_list', false);
    }, error => {
      console.log('Error ', error);
      this.loaders.manufacturer = false;
      this.alertService.error(error.error.message || 'Unable to list authors');
      this.utilService.responsiveTable('manufacturer_list', false);
    } );
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  openDialog(id, section) {
    switch (section) {
      case 'manufacturer': {
        this.manufacturerModal = {
          title: 'Create Author',
          btnText: 'SAVE'
        };
        this.activeManufacturer = null;
        this.resetManufacturer();
        this.utilService.openModal('createManufacturer');
        break;
      }
    }
  }

  public getVendors() {
    this.superAdminService.getVendors().subscribe((res: IResponse) => {
      console.log('Res ', res);
      this.vendors = res.data;
    }, error => {
      console.log('Error ', error);
      this.alertService.error(error.error.message || 'Unable to list vendors');
    } );
  }
  selectFiles(id) {
    $('#' + id).trigger('click');
  }
  resetManufacturer() {
    this.manufacturer = {
      manufacturer_name: null,
      sort_order: Math.floor((Math.random() * 100)),
      vendor_id: null,
      imageupload: null,
      image: null,
    };
  }

  public processSelectedFile(event, object) {
    const selectedFiles =  event.target.files;
    const reader = new FileReader();
    const file = selectedFiles[0];
    if (!isNullOrUndefined(file)) {
      reader.onloadend = () => {
        object.imageupload = reader.result;
      };
      reader.readAsDataURL(file);
    }
  }

  createManufacturer() {
    console.log('Create Manufacturers', this.manufacturer);
    if (!this.manufacturer.manufacturer_name) {
      return this.alertService.error('Author name is required');
    } else if (!this.manufacturer.imageupload && !this.manufacturer.image) {
      return this.alertService.error('Author logo is required');
    } else if (this.activeManufacturer) {
      this.loaders.saving = true;
      this.updateManufacturer();
    } else {
      this.loaders.saving = true;
      this.superAdminService.createManufacturer(this.manufacturer).subscribe((res: IResponse) => {
        this.getManufacturers();
        this.alertService.success(res.message || 'Author created successfully!');
        this.loaders.saving = false;
        this.utilService.closeModal('createManufacturer');
        this.resetManufacturer();
      }, error => {
        this.loaders.saving = false;
        this.alertService.error(error.error.message || 'Unable to create author!');
        console.log({error});
      });
    }
  }

  updateManufacturer() {
    this.superAdminService.updateManufacturer(this.activeManufacturer).subscribe((res: IResponse) => {
      this.getManufacturers();
      this.alertService.success(res.message || 'Author updated successfully!');
      this.loaders.saving = false;
      this.utilService.closeModal('createManufacturer');
      this.resetManufacturer();
    }, error => {
      this.loaders.saving = false;
      this.alertService.error(error.error.message || 'Unable to update author!');
      console.log({error});
    });
  }
  triggerEdit(data, id) {
    switch (id) {
      case 'manufacturer': {
        this.activeManufacturer = JSON.parse(JSON.stringify(data));
        this.resetManufacturer();
        this.manufacturer = this.activeManufacturer;
        this.manufacturerModal = {
          title: 'Update Author',
          btnText: 'UPDATE'
        };
        this.utilService.openModal('createManufacturer');
        break;
      }
    }
  }
  triggerDelete(data, id) {
    switch (id) {
      case 'manufacturer': {
        this.utilService.confirmAction(() => {
          this.superAdminService.deleteManufacturer(data.manufacturer_id)
            .subscribe((res: IResponse) => {
              console.log('Res ', res);
              this.getManufacturers();
            }, error => {
              console.log('Errro ', error);
            });
        });
        break;
      }
    }
  }
  validateNumber(id, object, key) {
    const value: any = $(`#${id}`).val();
    if (value === 0) {
      $(`#${id}`).val(1);
      object[key] = 1;
    }
    object[key] = Math.abs(value) || 1;
  }
  initPicker(id) {
    setTimeout(() => {
      (<any>$('#' + id)).datetimepicker({
        icons: {
          time: 'fa fa-clock-o',
          date: 'fa fa-calendar',
          up: 'fa fa-chevron-up',
          down: 'fa fa-chevron-down',
          previous: 'fa fa-chevron-left',
          next: 'fa fa-chevron-right',
          today: 'fa fa-screenshot',
          clear: 'fa fa-trash',
          close: 'fa fa-remove'
        },
        format: 'YYYY-MM-DD'
      });
    }, 400);
  }

  public updateField(id, object) {
    setTimeout(() => {
      const val = $('#' + id).val();
      object[id] = val;
    }, 1000);
  }

}
