import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-management',
  templateUrl: './page-management.component.html',
  styleUrls: ['./page-management.component.css']
})
export class PageManagementComponent implements OnInit {
breadCrumb: any;
  constructor() { }

  ngOnInit() {
    this.breadCrumb  = {
      name: 'Pages',
      parent: 'Home',
      subLink: null
    };
  }

}
