import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {environment as ENV} from '../../../../../environments/environment';
import {AuthService} from '../../../../services/authService/auth.service';
import {UserService} from '../../../../services/apiCalls/userService/user.service';

declare const $: any;
@Component({
  selector: 'app-s-sidebar',
  templateUrl: './s-sidebar.component.html',
  styleUrls: ['./s-sidebar.component.css']
})
export class SSidebarComponent implements OnInit, AfterViewInit {

  public reportFile: any;
  public currentUser: any;
  static updateActiveBar(url) {
    setTimeout(() => {
      console.info('URL : ', url);
      let oldUrl;
      oldUrl = 'dashboard';
      $('.nav-link-special').removeClass('active');
      $(`#${url}`).addClass('active');
      if ($(`#${url}`).hasClass('active')) {
        oldUrl = url;
      } else {
        $(`#${oldUrl}`).addClass('active');
      }
    }, 500);
  }
  constructor(
    private route: Router,
    private userService: UserService,
    private authService: AuthService
  ) {

  }
  ngOnInit() {
    this.currentUser =  JSON.parse(sessionStorage.getItem(ENV.USERTOKEN));

  }
  ngAfterViewInit() {
    const routes = this.route.url.split('/');
    SSidebarComponent.updateActiveBar(routes[routes.length - 1]);
    const navLink = document.querySelectorAll('.nav-link-special');
    navLink.forEach((el) => {
      // console.info('Ele ', el);
      el.addEventListener('click', (e) => {
        $('.navLinks').removeClass('active');
        $('.childNavDropDown').removeClass('in');
        const id = el['id'];
        // console.info('ellll ', el['id']);
        if (id.includes('#') || !id) {return false; }
        $(`#${id}`).addClass('active');
        $(`#${id} > ul`).addClass('in');

      });
    });
  }
  public logOut() {
    this.userService.logOut().subscribe(() => {
      this.authService.logOut();
    });
    this.authService.logOut();
    this.route.navigate(['/']);
  }
}
