import { Component, OnInit } from '@angular/core';
import {UtilService} from '../../services/utilService/util.service';
import {SuperAdminService} from '../../services/apiCalls/superAdminService/super-admin.service';
import { environment as ENV } from '../../../environments/environment';
import {IResponse} from '../../interfaces/iresponse';
import {UserService} from '../../services/apiCalls/userService/user.service';
import {BootstrapNotifyService} from '../../services/bootstrap-notify/bootstrap-notify.service';
import {isNullOrUndefined} from 'util';

@Component({
  selector: 'app-vendor-products',
  templateUrl: './vendor-products.component.html',
  styleUrls: ['./vendor-products.component.css']
})
export class VendorProductsComponent implements OnInit {
  breadCrumb: any;
  public EMAIL_VALIDATION: any =  ENV.EMAIL_VALIDATION;
  loaders = {
    saving: false
  };
  categories: any[] = [];
  products: any[] = [];
  vendors: any[] = [];
  manufacturers: any[] = [];
  downloadables: any[] = [];
  stockLists: any[] = [];
  weightClass: any[] = [];
  lenghtClass: any[] = [];
  activeProduct = null;
  product =  {
    product_name: null,
    description: null,
    weight: null,
    location: null,
    meta_title: null,
    meta_description: null,
    meta_keyword: null,
    image: null,
    images: null,
    sort_order: Math.floor((Math.random() * 100)),
    category_id: null,
    sku: null,
    isbn: null,
    mpn: null,
    manufacturer_id: null,
    imageupload: [],
    weightclass_id: 1,
    length: 1,
    lengthclass_id: 1,
    quantity: 1,
    purchase_price: 0.0,
    selling_price: 0.0,
    vendor_id: null
  };
  loadingData = false;
  changeOccured = true;
  imagePATH = null;
  imageHolder = null;
  public modal = {
    title: 'Create Product',
    btnText: 'SAVE'
  };
  currentUser = null;
  constructor(private utilService: UtilService,
              private userService: UserService,
              private alertService: BootstrapNotifyService,
              private bootstrapNotify: BootstrapNotifyService,
              private superAdminService: SuperAdminService) {
  }

  ngOnInit() {
    this.currentUser = this.utilService.getAuthUser();
    this.breadCrumb  = {
      name: 'Product',
      parent: 'Home',
      subLink: null
    };
    console.log('CUrrent USE%R ', this.currentUser);
    this.getProducts();
    this.getVendors();
    this.getManufacturers();
    this.getCategories();
  }

  openDialog(id) {
    this.modal = {
      title: 'Create Product',
      btnText: 'SAVE'
    };
    this.activeProduct = null;
    this.resetForm();
    this.utilService.openModal(id);
  }
  getProducts() {
    this.loadingData = true;
    this.changeOccured = false;
    this.superAdminService.getVendorProducts(this.currentUser.vendor_id)
      .subscribe((res: IResponse) => {
        console.log('Response ', res);
        this.products = res.data;
        this.utilService.startDatatable('products-list');
        this.loadingData = false;
        this.changeOccured = true;
      }, error => {
        this.utilService.startDatatable('products-list');
        this.loadingData = false;
        this.changeOccured = true;
        console.log('Error ', error);
      });
  }

  public deleteProduct(product) {
    this.utilService.confirmAction(() => {
      this.superAdminService.deleteProduct(product.product_id)
        .subscribe((res: IResponse) => {
          console.log('Res ', res);
          this.getProducts();
        }, error => {
          console.log('Errro ', error);

        });
    });
  }
  public triggerEdit(_product) {
    const product = JSON.parse(JSON.stringify(_product));
    this.activeProduct = product.product;

    this.resetForm();
    this.product = this.activeProduct;
    this.product.images = product.images;
    console.log('Product ', this.product);
    this.modal = {
      title: 'Update Product',
      btnText: 'UPDATE'
    };
    this.utilService.openModal('createProduct');
  }


  public createProduct() {
    console.log('Product ', this.product);
    this.loaders.saving = true;
    if (!this.product.product_name) {
      this.bootstrapNotify.info('Product name is Required');
      this.loaders.saving = false;
    } else if (!this.product.description) {
      this.bootstrapNotify.info('Product description is Required');
      this.loaders.saving = false;
    } else if (!this.product.meta_title) {
      this.bootstrapNotify.info('Meta title is Required');
      this.loaders.saving = false;
    } else if (!this.product.meta_description) {
      this.bootstrapNotify.info('Meta description is Required');
      this.loaders.saving = false;
    } else if (!this.product.meta_keyword) {
      this.bootstrapNotify.info('Meta keyword is Required');
      this.loaders.saving = false;
    } else {
      this.product.vendor_id = this.currentUser.vendor_id;
      if (this.activeProduct) {
        if (!this.product.image) {
          this.bootstrapNotify.info('Display image is Required');
          this.loaders.saving = false;
        }
        this.updateProduct();
      } else {
        if (!this.product.imageupload) {
          this.bootstrapNotify.info('Display image is Required');
          this.loaders.saving = false;
        }
        this.superAdminService.createProduct(this.product).subscribe((response: IResponse) => {
          console.log('Response', response);
          this.loaders.saving = false;
          this.resetForm();
          this.getProducts();
          this.utilService.closeModal('createProduct');
          this.bootstrapNotify.info(response.message || 'Product created successfully!');
        }, error => {
          this.bootstrapNotify.error(error.error.message || 'Unable to create product!', 'right');
          this.loaders.saving = false;
          console.info('Error => ', error);
        });
      }
    }

  }
  public updateProduct() {
    console.log('Active Product ', this.activeProduct);
    this.superAdminService.updateProduct(this.activeProduct, this.activeProduct.product_id).subscribe((response: IResponse) => {
      console.log('Response', response);
      this.loaders.saving = false;
      this.resetForm();
      this.getProducts();
      this.utilService.closeModal('createProduct');
      this.bootstrapNotify.success(response.message || 'Product updated successfully!');
    }, error => {
      this.bootstrapNotify.error(error.error.message || 'Unable to update subproduct!', 'right');
      this.loaders.saving = false;
      console.info('Error => ', error);
    });
  }
  public resetForm() {
    this.product =  {
      product_name: null,
      description: null,
      weight: null,
      meta_title: null,
      images: null,
      location: null,
      meta_description: null,
      meta_keyword: null,
      image: null,
      sort_order: Math.floor((Math.random() * 100)),
      category_id: null,
      sku: null,
      isbn: null,
      mpn: null,
      manufacturer_id: null,
      imageupload: [],
      weightclass_id: 1,
      length: 1,
      lengthclass_id: 1,
      quantity: 1,
      purchase_price: 0.0,
      selling_price: 0.0,
      vendor_id: null
    };
  }

  /*  public processSelectedFile(event) {
      const selectedFiles =  event.target.files;
      const reader = new FileReader();
      const file = selectedFiles[0];
      if (!isNullOrUndefined(file)) {
        reader.onloadend = () => {
          this.product.imageupload = this.imageHolder = reader.result;
        };
        reader.readAsDataURL(file);
      }
    }*//*
  public filterProduct(e) {
    console.log('Event ', e);
    this.getProducts(e.parent_id);
  }*/

  public processSelectedFile(event) {
    const selectedFiles =  event.target.files;
    // const reader = new FileReader();
    // const file = selectedFiles[0];
    // console.log('Hello ', selectedFiles);
    // this.product.imageupload = [selectedFiles];
    for (const i of selectedFiles) {
      /*  reader.onloadend = () => {
          object.imageupload.push(reader.result);
        };
        reader.readAsDataURL(i);*/
      this.getBase64(i).then((res) => {
        this.product.imageupload.push(res);
        console.log('Image s ', this.product.imageupload);
      });
    }
  }
  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  public getWeightClass(vendor_id) {
    this.superAdminService.getWeightClass(vendor_id).subscribe((res: IResponse) => {
      console.log('Res ', res);
      this.weightClass = res.data;
    }, error => {
      console.log('Error ', error);
      this.alertService.error(error.error.message || 'Unable to list weight classes');
    } );
  }


  /*public getProducts() {
    this.superAdminService.getProducts().subscribe((res: IResponse) => {
      console.log('Res ', res);
      this.products = res.data;
      this.getStockList(this.products[0].product.product_id);
      this.getDownloadables(this.products[0].product.product_id);
    }, error => {
      console.log('Error ', error);
      this.alertService.error(error.error.message || 'Unable to list products');
    } );
  }*//*
  public getStockList(product_id) {
    this.superAdminService.getStockList(product_id).subscribe((res: IResponse) => {
      console.log('Res ', res);
      this.stockLists = res.data;
    }, error => {
      console.log('Error ', error);
      this.alertService.error(error.error.message || 'Unable to list product stock list');
    } );
  }*/
  public getVendors() {
    this.superAdminService.getVendors().subscribe((res: IResponse) => {
      console.log('Res ', res);
      this.vendors = res.data;
      this.getWeightClass(this.vendors[0].vendor_id);
      this.getLengthClass(this.vendors[0].vendor_id);
    }, error => {
      console.log('Error ', error);
      this.alertService.error(error.error.message || 'Unable to list vendors');
    } );
  }

  public getLengthClass(vendor_id) {
    this.superAdminService.getLenghtClass(vendor_id).subscribe((res: IResponse) => {
      console.log('Res ', res);
      this.lenghtClass = res.data;
    }, error => {
      console.log('Error ', error);
      this.alertService.error(error.error.message || 'Unable to list length classes');
    } );
  }

  public getManufacturers() {
    this.superAdminService.getManufacturers().subscribe((res: IResponse) => {
      console.log('Res ', res);
      this.manufacturers = res.data;
    }, error => {
      console.log('Error ', error);
      this.alertService.error(error.error.message || 'Unable to list manufacturers');
    } );
  }

  getCategories() {
    this.superAdminService.getCategories()
      .subscribe((res: IResponse) => {
        console.log('Response ', res);
        this.categories = res.data;
      }, error => {      });
  }
}
