import {IRouting} from './interfaces/irouting';
import {RouterModule, Routes} from '@angular/router';
import {UserRoutesComponent} from './users/user-routes/user-routes.component';
import {LoginComponent} from './landing/login/login.component';
import {GuardService, RoleService} from './services/gaurdService/guard.service';
import {SuperUserRoutesComponent} from './super-users/super-user-routes/super-user-routes.component';
import {SuperDashboardComponent} from './super-users/super-dashboard/super-dashboard.component';
import {UserDashboardComponent} from './users/user-dashboard/user-dashboard.component';
import {SSidebarComponent} from './shared/layout/dashbord/s-sidebar/s-sidebar.component';
import {ResetPasswordComponent} from './landing/reset-password/reset-password.component';
import {SetPasswordComponent} from './landing/set-password/set-password.component';
import {UserManagementComponent} from './super-users/user-management/user-management.component';
import {ConfigurationsComponent} from './super-users/configurations/configurations.component';
import {UserGroupsManagementComponent} from './super-users/user-groups-management/user-groups-management.component';
import {SuperAdminService} from './services/apiCalls/superAdminService/super-admin.service';
import {ChangePasswordComponent} from './shared/components/change-password/change-password.component';
import {TransactionComponent} from './super-users/transaction/transaction.component';
import {VendorRegisterComponent} from './landing/vendor-register/vendor-register.component';
import {AdminRegisterComponent} from './landing/admin-register/admin-register.component';
import {CategoryComponent} from './super-users/category/category.component';
import {ProductManagementComponent} from './super-users/product-management/product-management.component';
import {CouponManagementComponent} from './super-users/coupon-management/coupon-management.component';
import {OrderManagementComponent} from './super-users/order-management/order-management.component';
import {SectionManagementComponent} from './super-users/section-management/section-management.component';
import {PageManagementComponent} from './super-users/page-management/page-management.component';
import {NotificationManagementComponent} from './super-users/notification-management/notification-management.component';
import {SeoManagementComponent} from './super-users/seo-management/seo-management.component';
import {VendorManagementComponent} from './super-users/vendor-management/vendor-management.component';
import {AdministratorManagementComponent} from './super-users/administrator-management/administrator-management.component';
import {SubcategoriesManagementComponent} from './super-users/subcategories-management/subcategories-management.component';
import {ProductConfigurationComponent} from './super-users/product-configuration/product-configuration.component';
import {VendorCategoryComponent} from './users/vendor-category/vendor-category.component';
import {VendorSubcategoryComponent} from './users/vendor-subcategory/vendor-subcategory.component';
import {VendorProductsComponent} from './users/vendor-products/vendor-products.component';
import {VendorTransactionReportComponent} from './users/vendor-transaction-report/vendor-transaction-report.component';
import {VendorNewOrderReportComponent} from "./users/vendor-new-order-report/vendor-new-order-report.component";
import {VendorLowStockReportComponent} from "./users/vendor-low-stock-report/vendor-low-stock-report.component";
import {VendorOutStockReportComponent} from "./users/vendor-out-stock-report/vendor-out-stock-report.component";
import {OutStockReportComponent} from "./super-users/out-stock-report/out-stock-report.component";
import {LowStockReportComponent} from "./super-users/low-stock-report/low-stock-report.component";
import {ProductWebviewComponent} from "./super-users/product-webview/product-webview.component";
import {FeaturedComponent} from "./super-users/featured/featured.component";
import {BestSellersComponent} from "./super-users/best-sellers/best-sellers.component";
import {OnsalesComponent} from "./super-users/onsales/onsales.component";
import {OndiscountComponent} from "./super-users/ondiscount/ondiscount.component";
import {WalletHistoryComponent} from "./super-users/wallet-history/wallet-history.component";
import {VoucherManagementComponent} from "./super-users/voucher-management/voucher-management.component";
import {CroppieComponent} from "./shared/components/croppie/croppie.component";
import {SalesByProductsComponent} from "./super-users/sales-by-products/sales-by-products.component";
import {SalesByCustomerComponent} from "./super-users/sales-by-customer/sales-by-customer.component";
import {VatManagementComponent} from "./super-users/vat-management/vat-management.component";
import {ManageBookCoverComponent} from "./super-users/product-configurations/manage-book-cover/manage-book-cover.component";
import {ManageAuthorsComponent} from "./super-users/product-configurations/manage-authors/manage-authors.component";
import {ManagePublishersComponent} from "./super-users/product-configurations/manage-publishers/manage-publishers.component";
import {AllProductsComponent} from "./super-users/all-products/all-products.component";

const landingRoutes: Routes = [
  {path: '', component: LoginComponent, pathMatch: 'full'},

  {path: 'login', component: LoginComponent},

  {path: 'register/vendor', component: VendorRegisterComponent},
  {path: 'register/admin', component: AdminRegisterComponent},

  {path: 'forgot-password', component: ResetPasswordComponent},
  {path: 'reset-password/token/:token', component: SetPasswordComponent},
  // {path: 'callback/fund-wallet-response', component: WalletResponseComponent },
  {path: 'vendor', loadChildren: './shared/modules/user/user.module#UserModule'},
  {path: 'superadministrator', loadChildren: './shared/modules/super-user/super-user.module#SuperUserModule'},
  // {path: 'vendor', loadChildren: './shared/modules/super-user/super-user.module#SuperUserModule'},

  {path: '**', component: LoginComponent},
];

export const landingRouting: IRouting = {
  routes: RouterModule.forRoot(landingRoutes, {useHash: false}),
  components: [
    LoginComponent,
    ResetPasswordComponent,
    AdminRegisterComponent,
    VendorRegisterComponent,
    SetPasswordComponent
  ],
  entryComponent: [],
  providers: []
};

// , canActivate: [GuardService]
export const userRoutes: Routes = [
  {
    path: '', component: UserRoutesComponent,
    children: [
      {path: 'dashboard', component: UserDashboardComponent, canActivate: [RoleService, GuardService], data: {roles: ['vendor']}},
      {path: 'manage-category', component: VendorCategoryComponent, canActivate: [RoleService, GuardService], data: {roles: ['vendor']}},
      {path: 'manage-subcategory', component: VendorSubcategoryComponent, canActivate: [RoleService, GuardService], data: {roles: ['vendor']}},
      {path: 'manage-products', component: VendorProductsComponent, canActivate: [RoleService, GuardService], data: {roles: ['vendor']}},
      {path: 'change-password', component: ChangePasswordComponent, canActivate: [RoleService, GuardService], data: {roles: ['vendor']}},
      {path: 'report/transaction', component: VendorTransactionReportComponent, canActivate: [RoleService, GuardService], data: {roles: ['vendor']}},
      {path: 'report/new-order', component: VendorNewOrderReportComponent, canActivate: [RoleService, GuardService], data: {roles: ['vendor']}},
      {path: 'report/out-of-stock', component: VendorOutStockReportComponent, canActivate: [RoleService, GuardService], data: {roles: ['vendor']}},
      {path: 'report/low-stock', component: VendorLowStockReportComponent, canActivate: [RoleService, GuardService], data: {roles: ['vendor']}}
    ]
  },
  {path: '**', component: UserRoutesComponent , redirectTo: 'dashboard'}
];


export const userRouting: IRouting = {
  routes: RouterModule.forChild(userRoutes),
  components: [
    UserRoutesComponent,
    UserDashboardComponent,
    VendorCategoryComponent,
    VendorSubcategoryComponent,
    VendorProductsComponent,
    VendorNewOrderReportComponent,
    VendorLowStockReportComponent,
    VendorOutStockReportComponent,
    VendorTransactionReportComponent,

  ],
  entryComponent: [],
  providers: []
};
// , canActivate: [RoleService, GuardService]
export const superUserRoutes: Routes = [
  {path: '', component: SuperUserRoutesComponent, children: [
      {path: 'dashboard', component: SuperDashboardComponent, data: {roles: ['superadministrator']}, canActivate: [GuardService, RoleService]},
      {path: 'report/transaction', component: TransactionComponent, canActivate: [RoleService, GuardService], data: {roles: ['superadministrator']}},
      {path: 'all-products', component: AllProductsComponent, canActivate: [RoleService, GuardService], data: {roles: ['superadministrator']}},
      {path: 'products', component: ProductManagementComponent, canActivate: [RoleService, GuardService], data: {roles: ['superadministrator']}},
      {path: 'products-view', component: ProductWebviewComponent, canActivate: [RoleService, GuardService], data: {roles: ['superadministrator']}},
      {path: 'category-management', component: CategoryComponent, canActivate: [RoleService, GuardService], data: {roles: ['superadministrator']}},
      {path: 'vat-management', component: VatManagementComponent, canActivate: [RoleService, GuardService], data: {roles: ['superadministrator']}},
      {path: 'sub-category-management', component: SubcategoriesManagementComponent, canActivate: [RoleService, GuardService], data: {roles: ['superadministrator']}},
      {path: 'product/coupon-management', component: CouponManagementComponent, canActivate: [RoleService, GuardService], data: {roles: ['superadministrator']}},
      {path: 'product/voucher-management', component: VoucherManagementComponent, canActivate: [RoleService, GuardService], data: {roles: ['superadministrator']}},

      {path: 'product/product-configuration', component: ProductConfigurationComponent, canActivate: [RoleService, GuardService], data: {roles: ['superadministrator']}},
      {path: 'product-config/manage-authors', component: ManageAuthorsComponent, canActivate: [RoleService, GuardService], data: {roles: ['superadministrator']}},
      {path: 'product-config/manage-publishers', component: ManagePublishersComponent, canActivate: [RoleService, GuardService], data: {roles: ['superadministrator']}},
      {path: 'product-config/manage-book-covers', component: ManageBookCoverComponent, canActivate: [RoleService, GuardService], data: {roles: ['superadministrator']}},




    {path: 'users/customers', component: UserManagementComponent, canActivate: [RoleService, GuardService], data: {roles: ['superadministrator']}},
      {path: 'users/vendors', component: VendorManagementComponent, canActivate: [RoleService, GuardService], data: {roles: ['superadministrator']}},
      {path: 'page-management', component: PageManagementComponent, canActivate: [RoleService, GuardService], data: {roles: ['superadministrator']}},
      {path: 'settings/seo', component: SeoManagementComponent, canActivate: [RoleService, GuardService], data: {roles: ['superadministrator']}},
      {path: 'settings/notification', component: NotificationManagementComponent, canActivate: [RoleService, GuardService], data: {roles: ['superadministrator']}},
      {path: 'section-management', component: SectionManagementComponent, canActivate: [RoleService, GuardService], data: {roles: ['superadministrator']}},
      {path: 'order-management', component: OrderManagementComponent, canActivate: [RoleService, GuardService], data: {roles: ['superadministrator']}},
      {path: 'users/administrators', component: AdministratorManagementComponent, canActivate: [RoleService, GuardService], data: {roles: ['superadministrator']}},
      {path: 'settings/change-password', component: ChangePasswordComponent, canActivate: [RoleService, GuardService], data: {roles: ['superadministrator']}},
      {path: 'settings/user-group', component: UserGroupsManagementComponent, canActivate: [RoleService, GuardService], data: {roles: ['superadministrator']}},
      {path: 'settings/configurations', component: ConfigurationsComponent, canActivate: [RoleService, GuardService], data: {roles: ['superadministrator']}},
      {path: 'report/out-of-stock', component: OutStockReportComponent, canActivate: [RoleService, GuardService], data: {roles: ['superadministrator']}},
      {path: 'report/transaction', component: TransactionComponent, canActivate: [RoleService, GuardService], data: {roles: ['superadministrator']}},
      {path: 'report/low-stock', component: LowStockReportComponent, canActivate: [RoleService, GuardService], data: {roles: ['superadministrator']}},
      {path: 'report/wallet-history', component: WalletHistoryComponent, canActivate: [RoleService, GuardService], data: {roles: ['superadministrator']}},
      {path: 'report/sales-by-product', component: SalesByProductsComponent, canActivate: [RoleService, GuardService], data: {roles: ['superadministrator']}},
      {path: 'report/customer-sales', component: SalesByCustomerComponent, canActivate: [RoleService, GuardService], data: {roles: ['superadministrator']}}


  ]},
  {path: '**', component: SuperUserRoutesComponent , redirectTo: 'dashboard'}
];

export const superUserRouting: IRouting = {
  routes: RouterModule.forChild(superUserRoutes),
  components: [
    SuperUserRoutesComponent,
    SuperDashboardComponent,
    SSidebarComponent,
    AllProductsComponent,
    UserManagementComponent,
    UserGroupsManagementComponent,
    TransactionComponent,
    ProductWebviewComponent,
    CategoryComponent,
    ConfigurationsComponent,
    ProductManagementComponent,
    CouponManagementComponent,
    OrderManagementComponent,
    SectionManagementComponent,
    PageManagementComponent,
    NotificationManagementComponent,
    SeoManagementComponent,
    VendorManagementComponent,
    AdministratorManagementComponent,
    SubcategoriesManagementComponent,
    ProductConfigurationComponent,
    VatManagementComponent,
    SalesByProductsComponent,
    SalesByCustomerComponent,
    CroppieComponent,
    FeaturedComponent,
    BestSellersComponent,
    OnsalesComponent,
    OndiscountComponent,
    WalletHistoryComponent,
    OutStockReportComponent,
    LowStockReportComponent,
    VoucherManagementComponent,


    ManageAuthorsComponent,
    ManagePublishersComponent,
    ManageBookCoverComponent,
  ],
  entryComponent: [],
  providers: []
};

