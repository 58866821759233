import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import {landingRouting} from './app.routing';
import {CoreModule} from './shared/modules/core/core.module';
import {SharedModules} from './shared/modules/shared/shared.module';
import { ManageAuthorsComponent } from './super-users/product-configurations/manage-authors/manage-authors.component';
import { ManagePublishersComponent } from './super-users/product-configurations/manage-publishers/manage-publishers.component';
import { ManageBookCoverComponent } from './super-users/product-configurations/manage-book-cover/manage-book-cover.component';
import { AllProductsComponent } from './super-users/all-products/all-products.component';

@NgModule({
  declarations: [
    AppComponent,
    landingRouting.components,
    // WalletResponseComponent

  ],
  imports: [
    BrowserModule,
    landingRouting.routes,
    CoreModule,
    SharedModules,
  ],
  providers: [landingRouting.providers],
  bootstrap: [AppComponent]
})
export class AppModule { }
