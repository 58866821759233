import { Component, OnInit } from '@angular/core';
import {CacheService} from '../../services/cacheService/cache.service';
import {NavigatorService} from '../../services/navigatorService/navigator.service';
import {IResponse} from '../../interfaces/iresponse';
import {UtilService} from '../../services/utilService/util.service';
import {ReportService} from '../../services/apiCalls/reportService/report.service';
import {UserService} from '../../services/apiCalls/userService/user.service';

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.css']
})
export class UserDashboardComponent implements OnInit {
  breadCrumb: any;
  public dashboard: any = null;
  constructor(private userService: UserService,
              private utilService: UtilService) {}

  ngOnInit() {
    this.setDashboard();
    this.breadCrumb  = {
      name: 'Dashboard',
      parent: 'Home',
      subLink: null
    };
  }

  public setDashboard() {
    this.userService.getDashboard()
      .subscribe((res: IResponse) => {
        console.log('Response ', res);
        this.dashboard = res.data;
      }, error => {
        console.log('Error ', error);
      });
  }
  public checkPrivilege(roles) {
    return this.utilService.checkPrivilegeNow(roles);
  }
}
