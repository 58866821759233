import { Component, OnInit } from '@angular/core';
import {SuperAdminService} from '../../services/apiCalls/superAdminService/super-admin.service';
import {UtilService} from '../../services/utilService/util.service';
import {BootstrapNotifyService} from '../../services/bootstrap-notify/bootstrap-notify.service';
import {IResponse} from '../../interfaces/iresponse';
import {isNullOrUndefined} from 'util';

@Component({
  selector: 'app-product-configuration',
  templateUrl: './product-configuration.component.html',
  styleUrls: ['./product-configuration.component.css']
})
export class ProductConfigurationComponent implements OnInit {
  breadCrumb: any;
  products: any[] = [];
  vendors: any[] = [];
  manufacturers: any[] = [];
  downloadables: any[] = [];
  stockStatus: any[] = [];
  stockLists: any[] = [];
  weightClass: any[] = [];
  bookCovers: any[] = [];
  lenghtClass: any[] = [];
  VATS = [];

  loaders = {
    weight: false,
    lenght: false,
    cover: false,
    manufacturer: false,
    stocking: false,
    downloadables: false,
    saving: false
  };
  manufacturerModal = {
    title: 'Create Manufacturer',
    btnText: 'SAVE',
  };
  stockModal = {
    title: 'Add to Stock',
    btnText: 'SAVE',
  };
  weightModal = {
    title: 'Create Weight Class',
    btnText: 'SAVE',
  };
  lengthModal = {
    title: 'Create Length Class',
    btnText: 'SAVE',
  };
  coverModal = {
    title: 'Create Bookcover',
    btnText: 'SAVE',
  };
  downloadableModal = {
    title: 'Create Downloadable',
    btnText: 'SAVE',
  };
  weight = {
    weightclass_name: null,
    unit: null
  };
  downloads = {
    fileupload: [],
    product_id: null
  };
  length = {
    lengthclass_name: null,
    unit: null
  };
  cover = {
    bookcover_name: null
  };
  manufacturer = {
    manufacturer_name: null,
    sort_order: Math.floor((Math.random() * 100)),
    vendor_id: null,
    imageupload: null,
    image: null,
  };
  stock = {
    minimum_qty: 1,
    quantity: 1,
    purchase_price: null,
    selling_price: null,
    vendor_id: null,
    location: null,
    product_id: null,
    date_available: null,
    stockstatus_id: 1,
    taxclass_id: 1,
  };
  activeManufacturer = null;
  activeStock = null;
  activeWeight = null;
  activeLength = null;
  activeDownload = null;
  activeCover = null;
  constructor(private superAdminService: SuperAdminService,
              private utilService: UtilService,
              private alertService: BootstrapNotifyService) {
    this.getProducts();
    this.getVendors();
    this.getStockStatus();
  }

  ngOnInit() {
    this.breadCrumb  = {
      name: 'Product Configurations',
      parent: 'Product',
      subLink: null
    };
    this.getManufacturers();
    this.getBookCovers();
    this.getVATS();
  }

  public getWeightClass(vendor_id) {
    this.loaders.weight = true;
    this.superAdminService.getWeightClass(vendor_id).subscribe((res: IResponse) => {
      console.log('Res ', res);
      this.loaders.weight = false;
      this.weightClass = res.data;
    }, error => {
      console.log('Error ', error);
      this.loaders.weight = false;
      this.alertService.error(error.error.message || 'Unable to list weight classes');
    } );
  }

  public getBookCovers() {
    this.loaders.cover = true;
    this.superAdminService.getBookCovers().subscribe((res: IResponse) => {
      console.log('Res ', res);
      this.loaders.cover = false;
      this.bookCovers = res.data;
    }, error => {
      console.log('Error ', error);
      this.loaders.cover = false;
      this.alertService.error(error.error.message || 'Unable to list book covers');
    } );
  }


  public getProducts() {
    this.superAdminService.getProducts().subscribe((res: IResponse) => {
      console.log('Res ', res);
      this.products = res.data;
      this.getStockList(this.products[0].product.product_id);
      this.getDownloadables(this.products[0].product.product_id);
    }, error => {
      console.log('Error ', error);
      this.alertService.error(error.error.message || 'Unable to list products');
    } );
  }
  public getStockList(product_id) {
    this.loaders.stocking = true;
    this.superAdminService.getStockList(product_id).subscribe((res: IResponse) => {
      console.log('Res ', res);
      this.stockLists = res.data;
      this.utilService.responsiveTable('stock_list', false);
      this.loaders.stocking = false;
    }, error => {
      console.log('Error ', error);
      this.loaders.stocking = false;
      this.utilService.responsiveTable('stock_list', false);
      this.alertService.error(error.error.message || 'Unable to list product stock list');
    } );
  }
  filterStockList(e) {
    this.getStockList(e.product.product_id);
  }
  filterDownloadables(e) {
    this.getDownloadables(e.product.product_id);
  }
  public getDownloadables(product_id) {
    this.loaders.downloadables = true;
    this.superAdminService.getDownloadables(product_id).subscribe((res: IResponse) => {
      console.log('Res ', res);
      this.downloadables = res.data;
      this.loaders.downloadables = false;
    }, error => {
      console.log('Error ', error);
      this.loaders.downloadables = false;
      this.alertService.error(error.error.message || 'Unable to list product downloadable files');
    } );
  }

  public getManufacturers() {
    this.loaders.manufacturer = true;
    this.superAdminService.getManufacturers().subscribe((res: IResponse) => {
      console.log('Res ', res);
      this.loaders.manufacturer = false;
      this.manufacturers = res.data;
      this.utilService.responsiveTable('manufacturer_list', false);
    }, error => {
      console.log('Error ', error);
      this.loaders.manufacturer = false;
      this.alertService.error(error.error.message || 'Unable to list manufacturers');
      this.utilService.responsiveTable('manufacturer_list', false);
    } );
  }
  public getStockStatus() {
    this.superAdminService.getStockStatus().subscribe((res: IResponse) => {
      this.stockStatus = res.data;
    }, error => {
      console.log('Error ', error);
    } );
  }

  public getVendors() {
    this.superAdminService.getVendors().subscribe((res: IResponse) => {
      console.log('Res ', res);
      this.vendors = res.data;
      this.getWeightClass(this.vendors[0].vendor_id);
      this.getLengthClass(this.vendors[0].vendor_id);
    }, error => {
      console.log('Error ', error);
      this.alertService.error(error.error.message || 'Unable to list vendors');
    } );
  }

  public getLengthClass(vendor_id) {
    this.loaders.lenght = true;
    this.superAdminService.getLenghtClass(vendor_id).subscribe((res: IResponse) => {
      console.log('Res ', res);
      this.loaders.lenght = false;
      this.lenghtClass = res.data;
    }, error => {
      console.log('Error ', error);
      this.loaders.lenght = false;
      this.alertService.error(error.error.message || 'Unable to list length classes');
    } );
  }

  public processSelectedFile(event, object) {
    const selectedFiles =  event.target.files;
    const reader = new FileReader();
    const file = selectedFiles[0];
    if (!isNullOrUndefined(file)) {
      reader.onloadend = () => {
        object.imageupload = reader.result;
      };
      reader.readAsDataURL(file);
    }
  }
  public processSelected(event, object) {
    const selectedFiles =  event.target.files;
    // const reader = new FileReader();
    // const file = selectedFiles[0];
    // console.log('Hello ', selectedFiles);
    // object.imageupload = [selectedFiles];
    for (const i of selectedFiles) {
    /*  reader.onloadend = () => {
        object.imageupload.push(reader.result);
      };
      reader.readAsDataURL(i);*/
    this.getBase64(i).then((res) => {
      object.fileupload.push(res);
    });
    }
  }
  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  openDialog(id, section) {
    switch (section) {
      case 'manufacturer': {
        this.manufacturerModal = {
          title: 'Create Manufacturer',
          btnText: 'SAVE'
        };
        this.activeManufacturer = null;
        this.resetManufacturer();
        this.utilService.openModal('createManufacturer');
        break;
      }
      case 'stock': {
        this.stockModal = {
          title: 'Add to Stock',
          btnText: 'SAVE'
        };
        this.activeStock = null;
        this.resetStock();
        this.initPicker('available_date');
        this.utilService.openModal('createStock');
        break;
      }
      case 'weight': {
        this.weightModal = {
          title: 'Create Weight Class',
          btnText: 'SAVE'
        };
        this.activeWeight = null;
        this.resetWeight();
        this.utilService.openModal('createWeight');
        break;
      }
      case 'cover': {
        this.coverModal = {
          title: 'Create Book Cover',
          btnText: 'SAVE'
        };
        this.activeCover = null;
        this.resetCover();
        this.utilService.openModal('bookCover');
        break;
      }
      case 'length': {
        this.lengthModal = {
          title: 'Create Length Class',
          btnText: 'SAVE'
        };
        this.activeLength = null;
        this.resetLength();
        this.utilService.openModal('createLength');
        break;
      }
      case 'downloadable': {
        this.lengthModal = {
          title: 'Create Downloadables',
          btnText: 'SAVE'
        };
        this.activeDownload = null;
        this.resetDownload();
        this.utilService.openModal('createDownload');
        break;
      }
    }
  }
  selectFiles(id) {
    $('#' + id).trigger('click');
  }
  resetManufacturer() {
    this.manufacturer = {
      manufacturer_name: null,
      sort_order: Math.floor((Math.random() * 100)),
      vendor_id: null,
      imageupload: null,
      image: null,
    };
  }
  resetStock() {
    this.stock = {
      minimum_qty: 1,
      purchase_price: null,
      selling_price: null,
      vendor_id: null,
      quantity: 1,
      location: null,
      product_id: null,
      date_available: null,
      stockstatus_id: 1,
      taxclass_id: 1,
    };
  }
  resetWeight() {
    this.weight = {
      weightclass_name: null,
      unit: null
    };
  }
  resetLength() {
    this.length = {
      lengthclass_name: null,
      unit: null
    };
  }
  resetCover() {
    this.cover = {
      bookcover_name: null
    };
  }
  resetDownload() {
    this.downloads = {
      fileupload: [],
      product_id: null
    };
  }
  createManufacturer() {
    console.log('Create Manufacturers', this.manufacturer);
    if (!this.manufacturer.manufacturer_name) {
      return this.alertService.error('Author name is required');
    } else if (!this.manufacturer.imageupload && !this.manufacturer.image) {
      return this.alertService.error('Author logo is required');
    } else if (this.activeManufacturer) {
      this.loaders.saving = true;
      this.updateManufacturer();
    } else {
      this.loaders.saving = true;
      this.superAdminService.createManufacturer(this.manufacturer).subscribe((res: IResponse) => {
        this.getManufacturers();
        this.alertService.success(res.message || 'Author created successfully!');
        this.loaders.saving = false;
        this.utilService.closeModal('createManufacturer');
        this.resetManufacturer();
      }, error => {
        this.loaders.saving = false;
        this.alertService.error(error.error.message || 'Unable to create manufacturer!');
        console.log({error});
      });
    }
  }
  createStock() {
    console.log('Create Stock', this.stock);
    if (!this.stock.selling_price) {
      return this.alertService.error('Selling price is required');
    } else if (!this.stock.purchase_price) {
      return this.alertService.error('Purchase price is required');
    } else if (!this.stock.quantity || this.stock.quantity < 1) {
      return this.alertService.error('Product quantity is required');
    } else if (this.activeStock) {
      this.loaders.saving = true;
      this.updateStock();
    } else {
      this.loaders.saving = true;
      this.superAdminService.createStock(this.stock).subscribe((res: IResponse) => {
        this.getStockList(this.stock.product_id);
        this.alertService.success(res.message || 'Stock added successfully!');
        this.loaders.saving = false;
        this.utilService.closeModal('createStock');
        this.resetStock();
      }, error => {
        this.loaders.saving = false;
        this.alertService.error(error.error.message || 'Unable to add to stock!');
        console.log({error});
      });
    }
  }
  createWeight() {
    console.log('Create Weight', this.weight);
    if (!this.weight.weightclass_name) {
      return this.alertService.error('Weight class name is required');
    } else if (!this.weight.unit) {
      return this.alertService.error('Weight unit is required');
    } else if (this.activeWeight) {
      this.loaders.saving = true;
      this.updateWeight();
    } else {
      this.loaders.saving = true;
      this.superAdminService.createWeight(this.weight).subscribe((res: IResponse) => {
        this.getWeightClass(this.vendors[0].vendor_id);
        this.alertService.success(res.message || 'Weight class created successfully!');
        this.loaders.saving = false;
        this.utilService.closeModal('createWeight');
        this.resetWeight();
      }, error => {
        this.loaders.saving = false;
        this.alertService.error(error.error.message || 'Unable to create weight!');
        console.log({error});
      });
    }
  }
  createLength() {
    console.log('Create Length', this.length);
    if (!this.length.lengthclass_name) {
      return this.alertService.error('Length class name is required');
    } else if (!this.length.unit) {
      return this.alertService.error('Length unit is required');
    } else if (this.activeLength) {
      this.loaders.saving = true;
      this.updateLength();
    } else {
      this.loaders.saving = true;
      this.superAdminService.createLength(this.length).subscribe((res: IResponse) => {
        this.getLengthClass(this.vendors[0].vendor_id);
        this.alertService.success(res.message || 'Length class created successfully!');
        this.loaders.saving = false;
        this.utilService.closeModal('createLength');
        this.resetLength();
      }, error => {
        this.loaders.saving = false;
        this.alertService.error(error.error.message || 'Unable to create length!');
        console.log({error});
      });
    }
  }
  createCover() {
    console.log('Create Cover', this.cover);
    if (!this.cover.bookcover_name) {
      return this.alertService.error('Book cover name is required');
    } else if (this.activeCover) {
      this.loaders.saving = true;
      this.updateCover();
    } else {
      this.loaders.saving = true;
      this.superAdminService.createCover(this.cover).subscribe((res: IResponse) => {
        this.getBookCovers();
        this.alertService.success(res.message || 'Book cover created successfully!');
        this.loaders.saving = false;
        this.utilService.closeModal('bookCover');
        this.resetCover();
      }, error => {
        this.loaders.saving = false;
        this.alertService.error(error.error.message || 'Unable to create book cover!');
        console.log({error});
      });
    }
  }
  createDownload() {
    console.log('Create Download', this.downloads);
    if (!this.downloads.fileupload.length) {
      return this.alertService.error('Upload files is required');
    } else if (!this.downloads.product_id) {
      return this.alertService.error('Product is required');
    } else {
      this.loaders.saving = true;
      this.superAdminService.createDownload(this.downloads).subscribe((res: IResponse) => {
        this.getDownloadables(res.data.product_id);
        this.alertService.success(res.message || 'File(s) added successfully!');
        this.loaders.saving = false;
        this.utilService.closeModal('createDownload');
        this.resetDownload();
      }, error => {
        this.loaders.saving = false;
        this.alertService.error(error.error.message || 'Unable to add file!');
        console.log({error});
      });
    }
  }
  updateWeight() {
    this.superAdminService.updateWeight(this.activeWeight).subscribe((res: IResponse) => {
      this.getWeightClass(this.vendors[0].vendor_id);
      this.alertService.success(res.message || 'Weight class updated successfully!');
      this.loaders.saving = false;
      this.utilService.closeModal('createWeight');
      this.resetWeight();
    }, error => {
      this.loaders.saving = false;
      this.alertService.error(error.error.message || 'Unable to update weight class!');
      console.log({error});
    });
  }
  updateLength() {
    this.superAdminService.updateLength(this.activeLength).subscribe((res: IResponse) => {
      this.getLengthClass(this.vendors[0].vendor_id);
      this.alertService.success(res.message || 'Length class updated successfully!');
      this.loaders.saving = false;
      this.utilService.closeModal('createLength');
      this.resetLength();
    }, error => {
      this.loaders.saving = false;
      this.alertService.error(error.error.message || 'Unable to update length class!');
      console.log({error});
    });
  }
  updateCover() {
    this.superAdminService.updateCover(this.activeCover).subscribe((res: IResponse) => {
      this.getBookCovers();
      this.alertService.success(res.message || 'Book cover updated successfully!');
      this.loaders.saving = false;
      this.utilService.closeModal('bookCover');
      this.resetCover();
    }, error => {
      this.loaders.saving = false;
      this.alertService.error(error.error.message || 'Unable to update book cover!');
      console.log({error});
    });
  }
  updateManufacturer() {
    this.superAdminService.updateManufacturer(this.activeManufacturer).subscribe((res: IResponse) => {
      this.getManufacturers();
      this.alertService.success(res.message || 'Manufacturer updated successfully!');
      this.loaders.saving = false;
      this.utilService.closeModal('createManufacturer');
      this.resetManufacturer();
    }, error => {
      this.loaders.saving = false;
      this.alertService.error(error.error.message || 'Unable to update manufacturer!');
      console.log({error});
    });
  }
  updateStock() {
    this.superAdminService.updateStock(this.activeStock).subscribe((res: IResponse) => {
      this.getStockList(this.stock.product_id);
      this.alertService.success(res.message || 'Stock list updated successfully!');
      this.loaders.saving = false;
      this.utilService.closeModal('createStock');
      this.resetStock();
    }, error => {
      this.loaders.saving = false;
      this.alertService.error(error.error.message || 'Unable to update stock list!');
      console.log({error});
    });
  }
  triggerEdit(data, id) {
    switch (id) {
      case 'manufacturer': {
        this.activeManufacturer = JSON.parse(JSON.stringify(data));
        this.resetManufacturer();
        this.manufacturer = this.activeManufacturer;
        this.manufacturerModal = {
          title: 'Update Manufacturer',
          btnText: 'UPDATE'
        };
        this.utilService.openModal('createManufacturer');
        break;
      }
      case 'stock': {
        this.activeStock = JSON.parse(JSON.stringify(data));
        this.resetStock();
        this.stock = this.activeStock;
        console.log('DATA ', data, this.activeStock);
        this.stockModal = {
          title: 'Update Stock',
          btnText: 'UPDATE'
        };
        this.initPicker('available_date');
        this.utilService.openModal('createStock');
        break;
      }
      case 'weight': {
        this.activeWeight = JSON.parse(JSON.stringify(data));
        this.resetWeight();
        this.weight = this.activeWeight;
        console.log('DATA ', data, this.activeWeight);
        this.weightModal = {
          title: 'Update Weight Class',
          btnText: 'UPDATE'
        };
        this.utilService.openModal('createWeight');
        break;
      }
      case 'cover': {
        this.activeCover = JSON.parse(JSON.stringify(data));
        this.resetCover();
        this.cover = this.activeCover;
        console.log('DATA ', data, this.activeCover);
        this.coverModal = {
          title: 'Update Book Cover',
          btnText: 'UPDATE'
        };
        this.utilService.openModal('bookCover');
        break;
      }
      case 'length': {
        this.activeLength = JSON.parse(JSON.stringify(data));
        this.resetLength();
        this.length = this.activeLength;
        console.log('DATA ', data, this.activeLength);
        this.lengthModal = {
          title: 'Update Length Class',
          btnText: 'UPDATE'
        };
        this.utilService.openModal('createLength');
        break;
      }
    }
  }
  triggerDelete(data, id) {
    switch (id) {
      case 'manufacturer': {
        this.utilService.confirmAction(() => {
          this.superAdminService.deleteManufacturer(data.manufacturer_id)
            .subscribe((res: IResponse) => {
              console.log('Res ', res);
              this.getManufacturers();
            }, error => {
              console.log('Errro ', error);
            });
        });
        break;
      }
    }
  }
  validateNumber(id, object, key) {
    const value: any = $(`#${id}`).val();
    if (value === 0) {
      $(`#${id}`).val(1);
      object[key] = 1;
    }
    object[key] = Math.abs(value) || 1;
  }
  initPicker(id) {
    setTimeout(() => {
      (<any>$('#' + id)).datetimepicker({
        icons: {
          time: 'fa fa-clock-o',
          date: 'fa fa-calendar',
          up: 'fa fa-chevron-up',
          down: 'fa fa-chevron-down',
          previous: 'fa fa-chevron-left',
          next: 'fa fa-chevron-right',
          today: 'fa fa-screenshot',
          clear: 'fa fa-trash',
          close: 'fa fa-remove'
        },
        format: 'YYYY-MM-DD'
      });
    }, 400);
  }

  public updateField(id, object) {
    setTimeout(() => {
      const val = $('#' + id).val();
      object[id] = val;
    }, 1000);
  }

  getVATS() {
    this.superAdminService.getVATS()
      .subscribe((res: IResponse) => {
        console.log('Response ', res);
        this.VATS = res.data;
      }, error => {
        console.log('Error ', error);
      });
  }

}
