import { Component, OnInit } from '@angular/core';
import {SuperAdminService} from "../../services/apiCalls/superAdminService/super-admin.service";
import {UtilService} from "../../services/utilService/util.service";
import {BootstrapNotifyService} from "../../services/bootstrap-notify/bootstrap-notify.service";
import {IResponse} from "../../interfaces/iresponse";

@Component({
  selector: 'app-order-management',
  templateUrl: './order-management.component.html',
  styleUrls: ['./order-management.component.css']
})
export class OrderManagementComponent implements OnInit {
  breadCrumb: any;
  orders = [];
  orderProducts = [];
  currentUser = null;
  loading = false;
  constructor(private utilService: UtilService,
              private userService: SuperAdminService,
              private alertService: BootstrapNotifyService) { }

  ngOnInit() {
    this.currentUser = this.utilService.getAuthUser();
    this.breadCrumb  = {
      name: 'Orders',
      parent: 'Home',
      subLink: null
    };
    this.getOrders();
  }
  getOrders() {
    this.loading = true;
    this.userService.getNewOrdersReport()
      .subscribe((res: IResponse) => {
        this.orders = res.data;
        this.alertService.success(res.message || 'New order report fetched!');
        this.utilService.startDatatable('order-list');
        this.loading = false;
      }, error => {
        console.log({error});
        this.loading = false;
        this.utilService.startDatatable('order-list');
        this.alertService.error(error.error.message || 'Unable to list new orders report!');
      });
  }
  viewProducts(order) {
    this.orderProducts = order.orderproducts;
    this.utilService.openModal('orderProducts');
  }
  /*
  getProducts() {
    this.loadingData = true;
    this.changeOccured = false;
    this.superAdminService.getProducts()
      .subscribe((res: IResponse) => {
        console.log('Response ', res);
        this.products = res.data;
        this.utilService.startDatatable('products-list');
        this.loadingData = false;
        this.changeOccured = true;
      }, error => {
        this.utilService.startDatatable('products-list');
        this.loadingData = false;
        this.changeOccured = true;
        console.log('Error ', error);
      });
  }*/
}
