import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-webview',
  templateUrl: './product-webview.component.html',
  styleUrls: ['./product-webview.component.css']
})
export class ProductWebviewComponent implements OnInit {
  breadCrumb: any;
 constructor() {}
  ngOnInit() {
    this.breadCrumb  = {
      name: 'Product View',
      parent: 'Home',
      subLink: null
    };
  }
}
