/**
 * Created by Arokoyu Olalekan Ojo
 */

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {IResponse} from '../../../interfaces/iresponse';
import {ApiService} from '../../api/api.service.';
import {DecryptService} from '../../decryptService/decrypt.service';
@Injectable()
export class UserService {

  constructor(private api: ApiService,
              private decryptionService: DecryptService) {

  }


  auth(data): Observable<IResponse> {
    return this.api.postRequest('login', 'authenticate' , data).map((res: IResponse)  => {
      return res ;
    });
  }



  createAgent(data: any): Observable<IResponse> {
    return this.api.postRequest('agents', null , data).map((res: IResponse)  => {
      return res ;
    });
  }

  createVendor(data: any): Observable<IResponse> {
    return this.api.postRequest('vendor', 'account/create' , data).map((res: IResponse)  => {
      return res ;
    });
  }
  createAdmin(data: any): Observable<IResponse> {
    return this.api.postRequest('admin', 'account/create' , data).map((res: IResponse)  => {
      return res ;
    });
  }

  public getDashboard(): Observable<IResponse> {
    return this.api.getRequest('dashboard', null).map( (res: IResponse) => {
      return res;
    });
  }
  public logOut(): Observable<IResponse> {
    return this.api.postRequest('auth', 'logout', {}).map((res: IResponse)  => {
      return res ;
    });
  }

  public resetPassword(data): Observable<IResponse> {
    return this.api.postRequest('auth/change', 'password', data).map( (res: IResponse) => {
      return res;
    });
  }
  public forgotPassword(data): Observable<IResponse> {
    return this.api.postRequest('auth', 'reset/password', data).map( (res: IResponse) => {
      return res;
    });
  }
  public updatePassword(data): Observable<IResponse> {
    return this.api.postRequest('auth', 'change-password', data).map( (res: IResponse) => {
      return res;
    });
  }
  public getAllUsers(): Observable<IResponse> {
    return this.api.getRequest('user', 'null').map( (res: IResponse) => {
      return res;
    });
  }
  public updateUser(data): Observable<IResponse> {
    return this.api.putRequest('user', data.id, data).map( (res: IResponse) => {
      return res;
    });
  }
  public getAllCountries(): Observable<IResponse> {
    return this.api.getRequest('country', 'list').map( (res: IResponse) => {
      return res;
    });
  }
  public getCountryZones(countryId): Observable<IResponse> {
    return this.api.getRequest('country/zone', 'list/' + countryId ).map( (res: IResponse) => {
      return res;
    });
  }
  public getAllState(): Observable<IResponse> {
    return this.api.getRequest('states', null).map( (res: IResponse) => {
      return res;
    });
  }
  public getAllZones(status?: any): Observable<IResponse> {
    if(status && status.all) {
      return this.api.getRequest('zones?all=' + status.all, null).map( (res: IResponse) => {
        return res;
      });
    } else {
      return this.api.getRequest('zones', null).map( (res: IResponse) => {
        return res;
      });
    }
  }
  public fundWalletInit(data): Observable<IResponse> {
    return this.api.postRequest('transactions/generate', 'payload', data).map( (res: IResponse) => {
      return res;
    });
  }
  public fundTransferInit(data): Observable<IResponse> {
    return this.api.postRequest('wallets', 'transfer', data).map( (res: IResponse) => {
      return res;
    });
  }
  public verifyTransaction(data): Observable<IResponse> {
    return this.api.postRequest('transactions/verify', 'payment', data).map( (res: IResponse) => {
      return res;
    });
  }
  public getPaymentChannels(): Observable<IResponse> {
    return this.api.getRequest('payment_channels', null).map( (res: IResponse) => {
      return res;
    });
  }
  public getWalletTransactions(): Observable<IResponse> {
    return this.api.getRequest('wallet', 'wallethistory').map( (res: IResponse) => {
      return res;
    });
  }
  public getTransactionById(id): Observable<IResponse> {
    return this.api.getRequest('transactions', id).map( (res: IResponse) => {
      return res;
    });
  }

  public getSharingFormulas(): Observable<IResponse> {
    return this.api.getRequest('/sharingFormulars', null).map( (res: IResponse) => {
      return res;
    });
  }
  public getMyAgents(): Observable<IResponse> {
    return this.api.getRequest('agents', null).map( (res: IResponse) => {
      return res;
    });
  }
  public getAgentForSuperAgent(super_agent_id): Observable<IResponse> {
    return this.api.getRequest('super-agent', 'agents/' + super_agent_id ).map( (res: IResponse) => {
      return res;
    });
  }
  public filterMyAgent(filter, superAgentId): Observable<IResponse> {
    if(!filter || filter.approved === null) {
      return this.api.getRequest('super-agent', 'agents/' + superAgentId).map( (res: IResponse) => {
        return res;
      });
    } else {
      return this.api.getRequest('super-agent', `agents/${superAgentId}?status=${ (filter.approved === 1) ? 'approved' : 'not_approved'}`).map( (res: IResponse) => {
        return res;
      });
    }
  }
  public toggleAgentStatus(actionType, agentId): Observable<IResponse> {
    return this.api.postRequest('super-agent', 'toggle-agent-status/' + agentId, actionType).map( (res: IResponse) => {
      return res;
    });
  }

  public getRiders(agentId): Observable<IResponse> {
    return this.api.getRequest('agent/riders', agentId).map( (res: IResponse) => {
      return res;
    });
  }
  public getFRSCRiders(): Observable<IResponse> {
    return this.api.getRequest('riders', null).map( (res: IResponse) => {
      return res;
    });
  }
  public updateFRSCRiders(data, id): Observable<IResponse> {
    return this.api.putRequest('riders', id, data).map( (res: IResponse) => {
      return res;
    });
  }
  public getTransactions(): Observable<IResponse> {
    return this.api.getRequest('transactions', null).map( (res: IResponse) => {
      return res;
    });
  }
  public getNewOrdersReport(vendor_id): Observable<IResponse> {
    return this.api.getRequest('report/neworder', 'list/' + vendor_id).map( (res: IResponse) => {
      return res;
    });
  }
  public getLowStockOrdersReport(vendor_id): Observable<IResponse> {
    return this.api.getRequest('report/lowstock', 'list/' + vendor_id).map( (res: IResponse) => {
      return res;
    });
  }
  public getOutStockOrdersReport(vendor_id): Observable<IResponse> {
    return this.api.getRequest('report/outofstock', 'list/' + vendor_id).map( (res: IResponse) => {
      return res;
    });
  }
  public getTransactionReport(vendor_id): Observable<IResponse> {
    return this.api.getRequest('report/transaction', 'list/' + vendor_id).map( (res: IResponse) => {
      return res;
    });
  }

  public getWallet(userId): Observable<IResponse> {
    return this.api.getRequest('wallets/'+ userId, 'balance').map( (res: IResponse) => {
      return res;
    });
  }
}
