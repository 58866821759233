import { Component, OnInit } from '@angular/core';
import {UtilService} from '../../../services/utilService/util.service';
import {BootstrapNotifyService} from '../../../services/bootstrap-notify/bootstrap-notify.service';
import {UserService} from '../../../services/apiCalls/userService/user.service';
import {IResponse} from '../../../interfaces/iresponse';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  public currentUser: any;
  public cloneCurrentUser: any;
  public isEditProfile: boolean;
  public isUpdating: boolean;
  constructor(private utilService: UtilService,
              private alertService: BootstrapNotifyService,
              private userService: UserService) {
    this.isEditProfile = false;
    this.isUpdating = false;
  }

  ngOnInit() {
    this.getAuthUser();
  }
  public getAuthUser() {
    this.currentUser = this.utilService.getAuthUser();
    console.info('Current User', this.currentUser);
    this.cloneCurrentUser = JSON.parse(JSON.stringify(this.currentUser));
  }
  public initEdit() {
    this.isEditProfile = true;
  }
  public updateProfile() {
    this.isUpdating = true;
    console.log('Current ', this.currentUser);
    if (!this.cloneCurrentUser.full_name) {
      this.alertService.error('Full name is required!');
      this.isUpdating = false;
      return;
    } else if (!this.cloneCurrentUser.phone_number) {
      this.alertService.error('Phone Number is required!');
      this.isUpdating = false;
      return;
    } else {
      const toUpdate  = {
        phone_number: this.cloneCurrentUser.phone_number,
        full_name: this.cloneCurrentUser.full_name,
        id: this.cloneCurrentUser.userId
      };
      this.userService.updateUser(toUpdate).subscribe((res: IResponse) => {
        console.log('Respomse ', res);
        this.isEditProfile = false;
        this.isUpdating = false;
        this.alertService.success('Profile Updated successfully, this will reflect next time you login!');
      }, error => {
        console.log('Error ', error);
        this.isEditProfile = false;
        this.isUpdating = false;
        this.alertService.error('Unable to update profile');
      });
    }
  }
}
