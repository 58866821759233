import { Component, OnInit } from '@angular/core';
import {UtilService} from '../../services/utilService/util.service';
import {UserService} from '../../services/apiCalls/userService/user.service';
import {IResponse} from '../../interfaces/iresponse';
import {BootstrapNotifyService} from '../../services/bootstrap-notify/bootstrap-notify.service';
import {EventsService} from '../../services/eventServices/event.service';
import {ActivatedRoute} from '@angular/router';
import {NavigatorService} from '../../services/navigatorService/navigator.service';

@Component({
  selector: 'app-wallet-history',
  templateUrl: './wallet-history.component.html',
  styleUrls: ['./wallet-history.component.css']
})
export class WalletHistoryComponent implements OnInit {
  breadCrumb: any;
  public transactions: any[] = [];
  loadingData = false;
  transactionsDetails = null;
  myAgents: any[] = [];
  currentUser = null;
  loaders = {
    saving: false,
    loading: false
  };
  fundTransfer = {
    narration: null,
    amount: 0.0,
    recipient_id: null
  };
  public transaction_reference = null;
  public response_code = null;
  public response_desc = null;
  public wallet = 0.0;
  constructor(private utilService: UtilService,
              private notifyService: BootstrapNotifyService,
              private eventService: EventsService,
              private navigateService: NavigatorService,
              private route: ActivatedRoute,
              private userService: UserService) {
    /* this.eventService.on('RELOAD_HISTORY', () => {
      this.getWalletTransactionList();
    });*/
    this.currentUser = this.utilService.getAuthUser();
    console.info('Current User', this.currentUser);
  }

  ngOnInit() {
    this.breadCrumb  = {
      name: 'Report',
      parent: 'Wallet-History',
      subLink: null
    };
    // this.getWallet(this.currentUser.id);
  /*  const query: any = this.route.snapshot.queryParams;
    this.transaction_reference = query.transaction_id;
    this.response_code = query['response_code'];
    this.response_desc = query['response_desc'];
    if (this.transaction_reference && this.response_code && this.response_desc) {
      this.notifyService.success('Redirect from payment gateways');
      this.verifyingPayment();
    }
    if (this.checkPrivilege(['super_agent'])) {
      console.log('Checking ROLE');
      this.getAllSuperAgents();
    } else {
      console.log('NOT SUPER AGENT');
    }*/
    this.getWalletTransactionList();
  }
  public verifyingPayment() {
    this.utilService.openModal('loadingModal');
    const verifyPayLoad = {
      transaction_reference: this.transaction_reference,
      response_code: this.response_code,
      response_desc: this.response_desc
    };
    this.notifyService.info(this.response_desc);
    this.userService.verifyTransaction(verifyPayLoad)
      .subscribe((res: IResponse) => {
      console.log('Res ', res);
      this.notifyService.success(res.message || 'Transaction successfully verified');
      this.navigateService.navigateUrl('/user/wallet-history');
    }, err => {
      console.log('Err ', err);
      this.utilService.closeModal('loadingModal');
        this.navigateService.navigateUrl('/user/wallet-history');

        this.notifyService.error(err.error.message || 'Unable to verify transaction with that code');
    });
  }

  getWalletTransactionList() {
    this.loadingData = true;
    this.userService.getWalletTransactions()
      .subscribe((res: IResponse) => {
        console.log('Response ', res);
        this.transactions = res.data;
        this.utilService.startDatatable('all-wallet-history');
        this.loadingData = false;
      }, error => {
        console.log('Error ', error);
        this.utilService.startDatatable('all-wallet-history');
        this.loadingData = false;
      });
  }
  getTransactionDetails(transaction) {
    this.utilService.openModal('transactionDetails');
    this.transactionsDetails = null;
    this.userService.getTransactionById(transaction.transaction_id)
      .subscribe((res: IResponse) => {
        console.log('Response ', res);
        this.transactionsDetails = res.data;
      }, error => {
        console.log('Error ', error);
      });
  }
  public triggerFundWallet() {
    this.eventService.broadcast('FUND_WALLET_NOW');
  }
  public triggerFundTransfer() {
    this.utilService.openModal('fundTransfer');
  }
  public checkPrivilege(roles) {
    return this.utilService.checkPrivilegeNow(roles);
  }

  public resetForm() {
    this.fundTransfer = {
      narration: null,
      amount: 0.0,
      recipient_id: null
    };
  }
  public proceedToFundTransfer() {
    if(!this.fundTransfer.recipient_id) {
      return this.notifyService.warning('Select recipient agent!');
    } else if(this.fundTransfer.amount < 0 || this.fundTransfer.amount === 0) {
      return this.notifyService.warning('Invalid Amount');
    } else {
      this.loaders.saving = true;
      this.userService.fundTransferInit(this.fundTransfer).subscribe((res: IResponse) => {
        this.loaders.saving = false;
        console.log('Transferring ', res);
        this.utilService.closeModal('fundTransfer');
        this.notifyService.success(res.message || 'Wallet fund transfer in progress');
        this.eventService.broadcast('RELOAD_HISTORY');
      }, error => {
        this.loaders.saving = false;
        this.notifyService.error(error.error.message || 'Unable to transfer wallet fund at this moment, please try again later!', 'right');
      });
    }
  }
  public validateAmount() {
    if(Math.sign(this.fundTransfer.amount) < 0) {
      this.fundTransfer.amount = this.fundTransfer.amount * -1;
    } if(this.fundTransfer.amount > this.wallet) {
      this.fundTransfer.amount = this.wallet;
      this.notifyService.info('Insufficient Fund: You cannot transfer more than '+ this.wallet);
    } else {
      return;
    }
  }
  getAllSuperAgents() {
    console.log('Log all SuperAgent agents');
    this.userService.filterMyAgent({approved: 1}, this.currentUser.owner.id)
      .subscribe((res: IResponse) => {
        console.log('Response ', res);
        this.myAgents = res.data;
        this.myAgents.forEach(agent => {
          agent.full_name_email = `${agent.full_name}(${agent.email})`;
        });
      }, error => {
        console.log('Error ', error);
      });
  }

  public getWallet(userId) {
    this.userService.getWallet(userId).subscribe((res: IResponse) => {
      console.log('Wallet Data ', res);
      this.wallet = res.data.balance || 0;
    }, error => {    });
  }
}
