import { Component, OnInit } from '@angular/core';
import {UtilService} from '../../services/utilService/util.service';
import {SuperAdminService} from '../../services/apiCalls/superAdminService/super-admin.service';
import { environment as ENV } from '../../../environments/environment';
import {IResponse} from '../../interfaces/iresponse';
import {UserService} from '../../services/apiCalls/userService/user.service';
import {BootstrapNotifyService} from '../../services/bootstrap-notify/bootstrap-notify.service';
import {isNullOrUndefined} from "util";

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {
  breadCrumb: any;
  public EMAIL_VALIDATION: any =  ENV.EMAIL_VALIDATION;
  loaders = {
    saving: false,
    subCategory: false
  };
  categories: any[] = [];
  subCategories: any[] = [];
  coupons: any[] = [];
  activeCategory = null;
  category =  {
    category_name: null,
    description: null,
    imageupload: null,
    meta_title: null,
    meta_description: null,
    meta_keyword: null,
    image: null
  };
  loadingData = false;
  changeOccured = true;
  imagePATH = null;
  imageHolder = null;
  subModalTitle = null;
  public modal = {
    title: 'Create Category',
    btnText: 'SAVE'
  };
  constructor(private utilService: UtilService,
              private userService: UserService,
              private bootstrapNotify: BootstrapNotifyService,
              private superAdminService: SuperAdminService) {

  }

  ngOnInit() {
    this.breadCrumb  = {
      name: 'Category',
      parent: 'Products',
      subLink: null
    };
    this.getCategories();
  }

  openDialog(id) {
    this.modal = {
      title: 'Create Category',
      btnText: 'SAVE'
    };
    this.activeCategory = null;
    this.resetForm();
    this.utilService.openModal(id);
  }
  getCategories() {
    this.loadingData = true;
    this.changeOccured = false;
    this.superAdminService.getCategories()
      .subscribe((res: IResponse) => {
        console.log('Response ', res);
        this.categories = res.data;
        // this.imagePATH = res.imagepath;
        this.utilService.startDatatable('category-list');
        this.loadingData = false;
        this.changeOccured = true;
      }, error => {
        this.utilService.startDatatable('category-list');
        this.loadingData = false;
        this.changeOccured = true;
        console.log('Error ', error);
      });
  }

  public deleteCategory(category) {
    this.utilService.confirmAction(() => {
      this.superAdminService.deleteCategory(category.category_id)
        .subscribe((res: IResponse) => {
          console.log('Res ', res);
          this.getCategories();
        }, error => {
          console.log('Errro ', error);

        });
    });
  }
  public triggerEdit(category) {
    this.activeCategory = JSON.parse(JSON.stringify(category));
    this.resetForm();
    this.category = this.activeCategory;
    this.modal = {
      title: 'Update Category',
      btnText: 'UPDATE'
    };
    this.utilService.openModal('createCategory');
  }


  public createCategory() {
    console.log('Category ', this.category);
    this.loaders.saving = true;
    if (!this.category.category_name) {
      this.bootstrapNotify.info('Category name is Required');
      this.loaders.saving = false;
    } else if (!this.category.description) {
      this.bootstrapNotify.info('Category description is Required');
      this.loaders.saving = false;
    } else if (!this.category.meta_title) {
      this.bootstrapNotify.info('Meta title is Required');
      this.loaders.saving = false;
    } else if (!this.category.meta_description) {
      this.bootstrapNotify.info('Meta description is Required');
      this.loaders.saving = false;
    } else if (!this.category.meta_keyword) {
      this.bootstrapNotify.info('Meta keyword is Required');
      this.loaders.saving = false;
    } else {
      if (this.activeCategory) {
        if (!this.category.image) {
          this.bootstrapNotify.info('Display image is Required');
          this.loaders.saving = false;
        }
        this.updateCategory();
      } else {
        if (!this.category.imageupload) {
          this.bootstrapNotify.info('Display image is Required');
          this.loaders.saving = false;
        }
        this.superAdminService.createCategory(this.category).subscribe((response: IResponse) => {
          console.log('Response', response);
          this.loaders.saving = false;
          this.resetForm();
          this.getCategories();
          this.utilService.closeModal('createCategory');
          this.bootstrapNotify.info(response.message || 'Category created successfully!');
        }, error => {
          this.bootstrapNotify.error(error.error.message || 'Unable to create category!', 'right');
          this.loaders.saving = false;
          console.info('Error => ', error);
        });
      }
    }

  }
  public updateCategory() {
    console.log('Active Category ', this.activeCategory);
    this.superAdminService.updateCategory(this.activeCategory, this.activeCategory.category_id).subscribe((response: IResponse) => {
      console.log('Response', response);
      this.loaders.saving = false;
      this.resetForm();
      this.getCategories();
      this.utilService.closeModal('createCategory');
      this.bootstrapNotify.info(response.message || 'Category updated successfully!');
    }, error => {
      this.bootstrapNotify.error(error.error.message || 'Unable to update category!', 'right');
      this.loaders.saving = false;
      console.info('Error => ', error);
    });
  }
  public resetForm() {
    this.category =  {
      category_name: null,
      description: null,
      imageupload: null,
      meta_title: null,
      meta_description: null,
      meta_keyword: null,
      image: null
    };
  }

  public processSelectedFile(event) {
    const selectedFiles =  event.target.files;
    const reader = new FileReader();
    const file = selectedFiles[0];
    if (!isNullOrUndefined(file)) {
      reader.onloadend = () => {
        this.category.imageupload = this.imageHolder = reader.result;
      };
      reader.readAsDataURL(file);
    }
  }
  public getSubcategories(category) {
    this.utilService.openModal('viewSubCategory');
    this.loaders.subCategory = true;
    this.superAdminService.getSubCategories(category.parent_id).subscribe((response: IResponse) => {
      console.log('SubCategory Response', response);
      this.subModalTitle = category.category_name + ' Sub categories';
      this.subCategories = response.data;
      this.utilService.startDatatable('sub-category-list');
      this.loaders.subCategory = false;
    }, error => {
      this.loaders.subCategory = false;
      this.bootstrapNotify.error(error.error.message || 'Unable to view sub-category!', 'right');
      this.utilService.startDatatable('sub-category-list');
      console.info('Error => ', error);
    });
  }

  getCoupons(category) {
    this.bootstrapNotify.info('Fetching coupons!');
    this.superAdminService.getCategoryCoupon(category.category_id)
      .subscribe((res: IResponse) => {
        console.log('Response ', res);
        this.coupons = res.data;
        if (this.coupons.length) {
          this.utilService.openModal('viewAttachCoupon');
        } else {
          this.bootstrapNotify.info('No coupon attached!');
        }
      }, error => {      });
  }
  removeCoupon(coupon) {
    this.superAdminService.deleteCategoryCoupon(coupon.couponcategory_id)
      .subscribe((res: IResponse) => {
        console.log('Response ', res);
        this.utilService.closeModal('viewAttachCoupon');
      }, error => {      });
  }
}
