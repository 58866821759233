import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import {Upload} from '../../models/upload';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  private basePath = '/am_alive_files';
  // upload: FirebaseListObservable<Upload[]>;
  constructor() { }
 public uploadImage (upload: Upload, creatorObj, callback) {
    const storageRef = firebase.storage().ref();
    const uploadTask = storageRef.child(`${this.basePath}/${upload.file.name}`).put(upload.file);
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) =>  {
        // upload in progress
        upload.progress = (uploadTask.snapshot.bytesTransferred / uploadTask.snapshot.totalBytes) * 100;
      },
      (error) => {
        // upload failed
        console.log(error);
      },
      () => {
        // upload success
        upload.url = uploadTask.snapshot.downloadURL;
        upload.name = upload.file.name;
        console.log('Upload', upload);
        creatorObj.companyLogo = upload.url;
        callback(creatorObj);
      }
    );
  }
  public uploadBase64 (upload, creatorObj, item, callback) {
    const storageRef = firebase.storage().ref();
    const itemForUpload = upload.file;
    console.log('File : ', upload);
    const uploadBase = itemForUpload.split(';base64,')[1];
    console.log('uploadBase ', uploadBase);
    const uploadTask = storageRef.child(`${this.basePath}/${upload.file.name}`).putString(uploadBase, 'base64');
    // split out the image/png or jpg out of string
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) =>  {
        // upload in progress
        upload.progress = (uploadTask.snapshot.bytesTransferred / uploadTask.snapshot.totalBytes) * 100;
      },
      (error) => {
        // upload failed
        console.log(error);
      },
      () => {
        // upload success
        upload.url = uploadTask.snapshot.downloadURL;
        upload.name = upload.file.name;
        console.log('Upload', upload);
        creatorObj[item] = upload.url;
        callback(creatorObj);
      }
    );
  }
  public uploadBlob (upload: Upload, creatorObj: object, callback: any, item: string, position?: null) {
    const storageRef = firebase.storage().ref();
    const uploadTask = storageRef.child(`${this.basePath}/${upload.file.name}`).put(upload.file);
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) =>  {
        // upload in progress
        upload.progress = (uploadTask.snapshot.bytesTransferred / uploadTask.snapshot.totalBytes) * 100;
      },
      (error) => {
        // upload failed
        console.log(error);
      },
      () => {
        // upload success
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          console.log('File available at', downloadURL);
          // upload success
          // upload.url = uploadTask.snapshot.downloadURL;
          upload.url = downloadURL;
          // upload.name = upload.file.name;
        });
      }
    );
  }
  public uploadMultiple(upload: Upload) {
    return new Promise( (resolve, reject) => {
      const storageRef = firebase.storage().ref();
      const uploadTask = storageRef.child(`${this.basePath}/${upload.file.name}`).put(upload.file);
      uploadTask.on('state_changed',
        function progress(snapshot) {        },
        function error(err) {
        reject({});
        },
        function complete() {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            console.log('File available at', downloadURL);
            upload.url = downloadURL;
            resolve({name: upload.file.name, url: upload.url});
          });
        }
      );
    });
  }
  public returnUploadImageUrl (upload: Upload): any {
    const storageRef = firebase.storage().ref();
    const uploadTask = storageRef.child(`${this.basePath}/${upload.file.name}`).put(upload.file);
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) =>  {
        // upload in progress
        upload.progress = (uploadTask.snapshot.bytesTransferred / uploadTask.snapshot.totalBytes) * 100;
      },
      (error) => {
        // upload failed
        console.log(error);
        return false;
      },
      () => {
        // upload success
        upload.url = uploadTask.snapshot.downloadURL;
        upload.name = upload.file.name;
        console.log('Uploading image instance');
        return upload.url;
      }
    );
  }
}
