import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-configurations',
  templateUrl: './configurations.component.html',
  styleUrls: ['./configurations.component.css']
})
export class ConfigurationsComponent implements OnInit {
  breadCrumb: any;
  constructor() { }

  ngOnInit() {
    this.breadCrumb  = {
      name: 'Configuration',
      parent: 'Settings',
      subLink: null
    };
  }

}
