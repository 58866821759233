import {Component, Input, OnInit} from '@angular/core';
import {UtilService} from '../../../../services/utilService/util.service';
// import * as $ from 'jquery';
@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {
  @Input() dataInput: any;
  pageTitle: string;
  userRole: string;
  currentUser = null;
  constructor(private utilService: UtilService) { }

  ngOnInit() {
    this.userRole = this.utilService.getAuthUserRole().toLowerCase() || 'vendor';
    this.pageTitle = 'Dashboard';
    // this.userRole = this.currentUser.role.toLowerCase() || 'user';
    console.log('USER data Input ', this.dataInput);
    this.pageTitle = this.dataInput.name;

  }
public handleFullWidth() {
    console.log('BREADCRUMB');
    const body = $('body');
    if (body.hasClass('layout-fullwidth')) {
      body.removeClass('layout-fullwidth');
      $('#fullWithToggler').removeClass('fa-arrow-right').addClass('fa-arrow-left');
    } else {
      body.addClass('layout-fullwidth');
      $('#fullWithToggler').removeClass('fa-arrow-left').addClass('fa-arrow-right');
    }
}
}
