import { Component, OnInit } from '@angular/core';
import { environment as ENV } from '../../../environments/environment';
import {BootstrapNotifyService} from '../../services/bootstrap-notify/bootstrap-notify.service';
import {UserService} from '../../services/apiCalls/userService/user.service';
import {IResponse} from '../../interfaces/iresponse';
import {NavigatorService} from '../../services/navigatorService/navigator.service';
import * as JWT_DECODE from 'jwt-decode';
import {CacheService} from '../../services/cacheService/cache.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../services/authService/auth.service';
import {EventsService} from "../../services/eventServices/event.service";

@Component({
  selector: 'app-admin-register',
  templateUrl: './admin-register.component.html',
  styleUrls: ['./admin-register.component.css']
})
export class AdminRegisterComponent implements OnInit {
  public EMAIL_VALIDATION: any = ENV.EMAIL_VALIDATION;
  public admin = {
    lastname: null,
    firstname: null,
    telephonenumber: null,
    username: null,
    email: null,
    password: null
  };
  public userDetails: any;
  loaders = {
    login: false,
    showResetLink: false
  };
  constructor(private bootstrapNotify: BootstrapNotifyService,
              private userService: UserService,
              private cacheService: CacheService,
              private navigateService: NavigatorService,
              private router: Router,
              private eventService: EventsService,
              private authService: AuthService,
              private activeRoute: ActivatedRoute) {
    this.authService.logOut();
  }

  ngOnInit(): void {
    this.userDetails = null;
  }

  public signupAdmin() {
    this.loaders.login = true;
    this.cacheService.deleteSession(ENV.TOKEN);
    this.cacheService.deleteStorage(ENV.TOKEN);
    this.cacheService.deleteStorage(ENV.USERTOKEN);
    this.cacheService.deleteSession(ENV.USERTOKEN);
    if (!this.admin.email) {
      this.bootstrapNotify.info('Email is Required');
      this.loaders.login = false;
    } else if (!this.admin.firstname) {
      this.bootstrapNotify.info('First Name is Required');
      this.loaders.login = false;
    } else if (!this.admin.lastname) {
      this.bootstrapNotify.info('Last Name is Required');
      this.loaders.login = false;
    } else if (!this.admin.username) {
      this.bootstrapNotify.info('Username is Required');
      this.loaders.login = false;
    } else if (!this.admin.password) {
      this.bootstrapNotify.info('Enter a secure password');
      this.loaders.login = false;
    } else {
      this.userService.createAdmin(this.admin).subscribe((response: IResponse) => {
        console.log('Response', response);
        this.loaders.login = false;
        this.resetSignup();
        this.bootstrapNotify.success('Registration successful, please login');
        this.eventService.broadcast('NEW_LOGIN');
        this.authService.handleRedirect(response);
      }, error => {
        this.loaders.login = false;
        this.bootstrapNotify.advance(error.error.message || 'Unable to register at this moment, please try again', 'right');
        console.info('Error => ', error);
      });
    }
  }
  public resetSignup() {
    this.admin = {
      lastname: null,
      firstname: null,
      telephonenumber: null,
      username: null,
      email: null,
      password: null
    };
  }
}
