import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {NotificationService} from '../../../services/notificationServices/notification.service';
import {ValidationErrorService} from '../../../services/validation-error-service/validation-error.service';
import {EventsService} from '../../../services/eventServices/event.service';
import {NavigatorService} from '../../../services/navigatorService/navigator.service';
import {EncryptDataService} from '../../../services/encryption/encrypt-data.service';
import {CacheService} from '../../../services/cacheService/cache.service';
import {GuardService, RoleService} from '../../../services/gaurdService/guard.service';
import {NotifyComponent} from '../../components/notify/notify.component';
import {SidebarComponent} from '../../layout/dashbord/sidebar/sidebar.component';
import {FooterComponent} from '../../layout/dashbord/footer/footer.component';
import {HeaderComponent} from '../../layout/dashbord/header/header.component';
import {DecryptService} from '../../../services/decryptService/decrypt.service';
import {NgSelectModule} from '@ng-select/ng-select';
import {ProfileComponent} from '../../components/profile/profile.component';
import {PageNotFoundComponent} from '../../components/page-not-found/page-not-found.component';
import {AsterisksPipe} from '../../pipes/Asterisks/asterisks.pipe';
import {ChangePasswordComponent} from '../../components/change-password/change-password.component';
import {BreadcrumbComponent} from '../../layout/dashbord/breadcrumb/breadcrumb.component';
import {SuperAdminService} from "../../../services/apiCalls/superAdminService/super-admin.service";
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    RouterModule
  ],
  declarations: [
    NotifyComponent,
    SidebarComponent,
    PageNotFoundComponent,
    FooterComponent,
    BreadcrumbComponent,
    HeaderComponent,
    ChangePasswordComponent,
    AsterisksPipe,
    ProfileComponent
  ],
  entryComponents: [],
  providers: [
    NotificationService,
    ValidationErrorService,
    EventsService,
    NavigatorService,
    DecryptService,
    GuardService,
    RoleService,
    EncryptDataService,
    CacheService,
    SuperAdminService,
    DecryptService
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NotifyComponent,
    SidebarComponent,
    PageNotFoundComponent,
    FooterComponent,
    BreadcrumbComponent,
    ChangePasswordComponent,
    HeaderComponent,
    ProfileComponent,
    AsterisksPipe,
    NgSelectModule
  ]
})
export class SharedModules { }
