import { Component, OnInit } from '@angular/core';
import {UtilService} from "../../services/utilService/util.service";
import {SuperAdminService} from "../../services/apiCalls/superAdminService/super-admin.service";
import {BootstrapNotifyService} from "../../services/bootstrap-notify/bootstrap-notify.service";
import {IResponse} from "../../interfaces/iresponse";

@Component({
  selector: 'app-voucher-management',
  templateUrl: './voucher-management.component.html',
  styleUrls: ['./voucher-management.component.css']
})
export class VoucherManagementComponent implements OnInit { breadCrumb: any;
  vouchers = [];
  categories = [];
  products = [];
  currentUser = null;
  loading = false;
  modal = {
    title: 'Create Voucher',
    btnText: 'SAVE'
  };
  voucher = {
    name: null,
    type: 'percentage',
    status: 1,
    logged: 1,
    discount: 0,
    shipping: 0,
    total: 0,
    uses_total: 0,
    uses_customer: 0,
    date_start: null,
    date_end: null,
  };
  activeVoucher = null;
  loaders = {
    saving: false
  };
  catToAttach = null;
  prodToAttach = null;
  toProduct = false;
  toCategory = false;
  constructor(private utilService: UtilService,
              private superAdminService: SuperAdminService,
              private alertService: BootstrapNotifyService) { }

  ngOnInit() {
    this.currentUser = this.utilService.getAuthUser();
    this.breadCrumb  = {
      name: 'Voucher',
      parent: 'Products',
      subLink: null
    };
    this.getVouchers();
    this.getProducts();
    this.getCategories();
  }
  getVouchers() {
    this.loading = true;
    this.superAdminService.getVouchers()
      .subscribe((res: IResponse) => {
        this.vouchers = res.data;
        console.log('RES copons ', res);
        this.utilService.startDatatable('vouchers-list');
        this.alertService.success(res.message || 'Vouchers listed successfully!');
        this.loading = false;
      }, error => {
        console.log({error});
        this.loading = false;
        this.utilService.startDatatable('vouchers-list');
        this.alertService.error(error.error.message || 'Unable to list vouchers!');
      });
  }
  openDialog(id) {
    this.modal = {
      title: 'Create Voucher',
      btnText: 'SAVE'
    };
    this.activeVoucher = null;
    this.resetForm();
    this.utilService.openModal(id);
    this.initPicker();
  }
  resetForm() {
    this.voucher = {
      name: null,
      type: 'percentage',
      status: 1,
      logged: 1,
      discount: 0,
      shipping: 0,
      total: 0,
      uses_total: 0,
      uses_customer: 0,
      date_start: null,
      date_end: null,
    };
  }
  deleteVoucher(voucher) {
    this.superAdminService.deleteVoucher(voucher.voucher_id).subscribe((res: IResponse) => {
      this.alertService.success(res.message || 'Voucher deleted successfully!');
      this.getVouchers();
    }, error => {
      console.log('Error ', error);
      this.alertService.error(error.error.message || 'Unable to delete voucher');
    });
  }
  public triggerEdit(voucher) {
    this.activeVoucher = JSON.parse(JSON.stringify(voucher));
    this.resetForm();
    this.voucher = this.activeVoucher;
    this.modal = {
      title: 'Update Voucher',
      btnText: 'UPDATE'
    };
    this.utilService.openModal('createVoucher');
    this.initPicker();
  }
  public createVoucher() {
    console.log('Voucher ', this.voucher);
    this.loaders.saving = true;
    if (!this.voucher.name) {
      this.alertService.info('Voucher name is Required');
      this.loaders.saving = false;
    } else if (!this.voucher.discount) {
      this.alertService.info('Voucher discount is Required');
      this.loaders.saving = false;
    } else if (!this.voucher.total) {
      this.alertService.info('Minimum order amount is required!');
      this.loaders.saving = false;
    } else {
      if (this.activeVoucher) {
        this.updateVoucher();
      } else {
        this.superAdminService.createVoucher(this.voucher).subscribe((response: IResponse) => {
          console.log('Response', response);
          this.loaders.saving = false;
          this.resetForm();
          this.getVouchers();
          this.utilService.closeModal('createVoucher');
          this.alertService.info(response.message || 'Voucher created successfully!');
        }, error => {
          this.alertService.error(error.error.message || 'Unable to create voucher!', 'right');
          this.loaders.saving = false;
          console.info('Error => ', error);
        });
      }
    }

  }
  public updateVoucher() {
    console.log('Active Voucher ', this.activeVoucher);
    this.superAdminService.updateVoucher(this.activeVoucher).subscribe((response: IResponse) => {
      console.log('Response', response);
      this.loaders.saving = false;
      this.resetForm();
      this.getVouchers();
      this.utilService.closeModal('createVoucher');
      this.alertService.info(response.message || 'Voucher updated successfully!');
    }, error => {
      this.alertService.error(error.error.message || 'Unable to update voucher!', 'right');
      this.loaders.saving = false;
      console.info('Error => ', error);
    });
  }
  initPicker() {
    setTimeout(() => {
      (<any>$('.datepicker')).datetimepicker({
        icons: {
          time: 'fa fa-clock-o',
          date: 'fa fa-calendar',
          up: 'fa fa-chevron-up',
          down: 'fa fa-chevron-down',
          previous: 'fa fa-chevron-left',
          next: 'fa fa-chevron-right',
          today: 'fa fa-screenshot',
          clear: 'fa fa-trash',
          close: 'fa fa-remove'
        },
        format: 'YYYY-MM-DD'
      });
    }, 400);
  }

  public updateField(id) {
    setTimeout(() => {
      const val = $('#' + id).val();
      this.voucher[id] = val;
    }, 1000);
  }

  getCategories() {
    this.superAdminService.getCategories()
      .subscribe((res: IResponse) => {
        this.categories = res.data;
      }, error => {
        console.log('Error ', error);
      });
  }
  getProducts() {
    this.superAdminService.getProducts()
      .subscribe((res: IResponse) => {
        this.products = res.data;
      }, error => {
        console.log('Error ', error);
      });
  }

  showProduct(voucher) {
    this.activeVoucher = voucher;
    this.toProduct = true;
    this.toCategory = false;
    this.utilService.openModal('attachVoucher');
  }
  showCategories(voucher) {
    this.activeVoucher = voucher;
    this.toProduct = false;
    this.toCategory = true;
    this.utilService.openModal('attachVoucher');
  }
  closeAttach() {
    this.utilService.closeModal('attachVoucher');
    this.activeVoucher = null;
    this.toProduct = false;
    this.toCategory = false;
  }
  saveAttachment() {
    this.loaders.saving = true;
    if (this.toCategory) {
      this.superAdminService.attachVoucherCategory({category_id: this.catToAttach, voucher_id: this.activeVoucher.voucher_id})
        .subscribe((res: IResponse) => {
          this.alertService.success(res.message || 'Voucher attached successfully');
          this.loaders.saving = false;
          console.log({res});
          this.closeAttach();
        }, error => {
          this.alertService.error(error.error.message || 'Unable to attach voucher to category');
          this.loaders.saving = false;
          console.log({error});
          this.closeAttach();
        });
    } else if (this.toProduct) {
      this.superAdminService.attachVoucherProduct({product_id: this.prodToAttach, voucher_id: this.activeVoucher.voucher_id})
        .subscribe((res: IResponse) => {
          this.alertService.success(res.message || 'Voucher attached successfully');
          this.loaders.saving = false;
          console.log({res});
          this.closeAttach();
        }, error => {
          this.alertService.error(error.error.message || 'Unable to attach voucher to product');
          this.loaders.saving = false;
          console.log({error});
          this.closeAttach();
        });
    } else {
      this.alertService.info('Unable to detect attachment type!');
      this.closeAttach();
    }
  }
}

