import { Component, OnInit } from '@angular/core';
import {SuperAdminService} from '../../services/apiCalls/superAdminService/super-admin.service';
import {BootstrapNotifyService} from '../../services/bootstrap-notify/bootstrap-notify.service';
import {IResponse} from '../../interfaces/iresponse';
import {UtilService} from '../../services/utilService/util.service';
import { environment as ENV } from '../../../environments/environment';

@Component({
  selector: 'app-administrator-management',
  templateUrl: './administrator-management.component.html',
  styleUrls: ['./administrator-management.component.css']
})
export class AdministratorManagementComponent implements OnInit {
  breadCrumb: any;
  administrators: any[] = [];
  loaders = {
  loading: false,
  loadingData: false,
    saving: false,
  changeOccured: false
};
  userGroups: any[] = [];
  public modal = {
    title: 'Create Admin',
    btnText: 'CREATE'
  };
  public EMAIL_VALIDATION: any = ENV.EMAIL_VALIDATION;
  public admin = {
    lastname: null,
    firstname: null,
    telephonenumber: null,
    username: null,
    email: null,
    usergroup_id: null,
    password: null
  };
  activeAdministrator = null;

constructor(private superAdminService: SuperAdminService,
  private utilService: UtilService,
  private alertService: BootstrapNotifyService) { }
ngOnInit() {
  this.breadCrumb  = {
    name: 'Administrators',
    parent: 'Users',
    subLink: null
  };
  this.getAdministrators();
  this.getUserGroup();
}
getAdministrators() {
  this.loaders.loadingData = true;
  this.loaders.changeOccured = false;
  this.superAdminService.getAdministrators().subscribe((res: IResponse) => {
    this.loaders.loadingData = false;
    this.loaders.changeOccured = true;
    this.administrators = res.data;
    console.log('RESponse ', res);
    this.utilService.startDatatable('admin-list');
    this.alertService.success(res.message || 'Admin listed successfully');
  }, error => {
    console.log('Errors ', error);
    this.alertService.error(error.error.message || 'Unable to list admin');
    this.utilService.startDatatable('admin-list');
    this.loaders.loadingData = false;
    this.loaders.changeOccured = true;
  });

}
  getUserGroup() {
  this.superAdminService.getUserGroups().subscribe((res: IResponse) => {
    console.log('RESponse ', res);
    this.userGroups = res.data;
  }, error => {
    console.log('Errors ', error);
    this.alertService.error(error.error.message || 'Unable to list user group');
  });

}

public triggerEdit(administrator) {
  this.resetForm();
  this.modal = {
    title: 'Update Admin',
    btnText: 'UPDATE'
  };
  this.activeAdministrator = this.admin = JSON.parse(JSON.stringify(administrator));
  this.utilService.openModal('createAdmin');
}
  public createAdmin() {
    this.loaders.saving = true;
    if (!this.admin.email) {
      this.alertService.info('Email is Required');
      this.loaders.saving = false;
    } else if (!this.admin.firstname) {
      this.alertService.info('First Name is Required');
      this.loaders.saving = false;
    } else if (!this.admin.lastname) {
      this.alertService.info('Last Name is Required');
      this.loaders.saving = false;
    } else if (!this.admin.username) {
      this.alertService.info('Username is Required');
      this.loaders.saving = false;
    } else if (!this.admin.usergroup_id) {
      this.alertService.info('User group is required');
      this.loaders.saving = false;
    }  else {
      if (this.activeAdministrator) {
        delete this.activeAdministrator.password;
        this.updateAdmin();
      } else {
        if (!this.admin.password) {
          this.alertService.info('Enter a secure password');
          this.loaders.saving = false;
        }
        this.superAdminService.createAdmin(this.admin).subscribe((response: IResponse) => {
          console.log('Response', response);
          this.loaders.saving = false;
          this.resetForm();
          this.alertService.success('Admin created successfully!');
          this.utilService.closeModal('createAdmin');
        }, error => {
          this.loaders.saving = false;
          this.alertService.advance(error.error.message || 'Unable to create admin', 'right');
          console.info('Error => ', error);
        });
      }
    }
  }
  public updateAdmin() {
    this.superAdminService.updateAdmin(this.activeAdministrator).subscribe((response: IResponse) => {
      console.log('Response', response);
      this.loaders.saving = false;
      this.resetForm();
      this.alertService.success('Admin updated successfully!');
      this.utilService.closeModal('createAdmin');
    }, error => {
      this.loaders.saving = false;
      this.alertService.advance(error.error.message || 'Unable to update admin', 'right');
      console.info('Error => ', error);
    });
  }
  public resetForm() {
    this.admin = {
      lastname: null,
      firstname: null,
      telephonenumber: null,
      username: null,
      email: null,
      usergroup_id: null,
      password: null
    };
  }
  openDialog(id) {
    this.modal = {
      title: 'Create Admin',
      btnText: 'CREATE'
    };
    this.activeAdministrator = null;
    this.resetForm();
    this.utilService.openModal(id);
  }

}

