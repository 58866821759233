import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Rx';
import {IResponse} from '../../../interfaces/iresponse';
import {ApiService} from '../../api/api.service.';

@Injectable()
export class SuperAdminService {

  constructor(private api: ApiService) { }
  public getDashboard(): Observable<IResponse> {
    return this.api.getRequest('dashboard', null).map( (res: IResponse) => {
      return res;
    });
  }
  public createCategory(data): Observable<IResponse> {
    return this.api.postRequest('category', 'create', data).map( (res: IResponse) => {
      return res;
    });
  }
  public createProduct(data): Observable<IResponse> {
    return this.api.postRequest('product', 'create', data).map( (res: IResponse) => {
      return res;
    });
  }
  public createSubCategory(data): Observable<IResponse> {
    return this.api.postRequest('subcategory', 'create', data).map( (res: IResponse) => {
      return res;
    });
  }
  public updateCategory(data, id): Observable<IResponse> {
    return this.api.putRequest('category', 'update', data).map( (res: IResponse) => {
      return res;
    });
  }
  public updateProduct(data, id): Observable<IResponse> {
    return this.api.putRequest('product', 'update', data).map( (res: IResponse) => {
      return res;
    });
  }
  public updateSubCategory(data, id): Observable<IResponse> {
    return this.api.putRequest('subcategory', 'update', data).map( (res: IResponse) => {
      return res;
    });
  }
  public getCategories(): Observable<IResponse> {
    return this.api.getRequest('category', 'list').map( (res: IResponse) => {
      return res;
    });
  }
  public getMegaCategories(): Observable<IResponse> {
    return this.api.getRequest('megacategory', 'list').map( (res: IResponse) => {
      return res;
    });
  }
  public getProductCoupon(product_id): Observable<IResponse> {
    return this.api.getRequest('couponproduct', 'product/' + product_id).map( (res: IResponse) => {
      return res;
    });
  }
  public getCategoryCoupon(category_id): Observable<IResponse> {
    return this.api.getRequest('couponcategory', 'coupon/' + category_id).map( (res: IResponse) => {
      return res;
    });
  }
  public deleteProductCoupon(couponproduct_id): Observable<IResponse> {
    return this.api.deleteRequest('couponproduct', couponproduct_id).map( (res: IResponse) => {
      return res;
    });
  }
  public deleteCategoryCoupon(couponcategory_id): Observable<IResponse> {
    return this.api.deleteRequest('couponcategory', couponcategory_id).map( (res: IResponse) => {
      return res;
    });
  }
  public getSubCategories(category_id): Observable<IResponse> {
    return this.api.getRequest('subcategory', 'list/' + category_id).map( (res: IResponse) => {
      return res;
    });
  }
  public getProducts(): Observable<IResponse> {
    return this.api.getRequest('product', 'list').map( (res: IResponse) => {
      return res;
    });
  }
  public getProductById(id): Observable<IResponse> {
    return this.api.getRequest('product', 'show/' + id).map( (res: IResponse) => {
      return res;
    });
  }
  public getBookCovers(): Observable<IResponse> {
    return this.api.getRequest('bookcover', 'list').map( (res: IResponse) => {
      return res;
    });
  }
  public getFeaturedProducts(): Observable<IResponse> {
    return this.api.getRequest('featuredproduct', 'list').map( (res: IResponse) => {
      return res;
    });
  }
  public getOnSalesProducts(): Observable<IResponse> {
    return this.api.getRequest('onsalesproduct', 'list').map( (res: IResponse) => {
      return res;
    });
  }
  public getBestSellerProducts(): Observable<IResponse> {
    return this.api.getRequest('bestsellerproduct', 'list').map( (res: IResponse) => {
      return res;
    });
  }
  public getVendorProducts(vendor_id): Observable<IResponse> {
    return this.api.getRequest('product', 'vendor/list/' + vendor_id).map( (res: IResponse) => {
      return res;
    });
  }
  public getVendors(): Observable<IResponse> {
    return this.api.getRequest('vendor', 'list').map( (res: IResponse) => {
      return res;
    });
  }
  public getStockList(product_id): Observable<IResponse> {
    return this.api.getRequest('productstock', 'list/' + product_id).map( (res: IResponse) => {
      return res;
    });
  }
  public getDownloadables(product_id): Observable<IResponse> {
    return this.api.getRequest('productdownload', 'list/' + product_id).map( (res: IResponse) => {
      return res;
    });
  }
  public getManufacturers(): Observable<IResponse> {
    return this.api.getRequest('manufacturer', 'list').map( (res: IResponse) => {
      return res;
    });
  }
  public getPublishers(): Observable<IResponse> {
    return this.api.getRequest('publishers', null).map( (res: IResponse) => {
      return res;
    });
  }
  public getStockStatus(): Observable<IResponse> {
    return this.api.getRequest('status', 'list').map( (res: IResponse) => {
      return res;
    });
  }
  public createManufacturer(data): Observable<IResponse> {
    return this.api.postRequest('manufacturer', 'create', data).map( (res: IResponse) => {
      return res;
    });
  }
  public createPublisher(data): Observable<IResponse> {
    return this.api.postRequest('publishers', null, data).map( (res: IResponse) => {
      return res;
    });
  }
  public createStock(data): Observable<IResponse> {
    return this.api.postRequest('stock', 'add', data).map( (res: IResponse) => {
      return res;
    });
  }
  public createWeight(data): Observable<IResponse> {
    return this.api.postRequest('weightclass', 'create', data).map( (res: IResponse) => {
      return res;
    });
  }
  public updateWeight(data): Observable<IResponse> {
    return this.api.postRequest('weightclass', 'update', data).map( (res: IResponse) => {
      return res;
    });
  }
  public updatePublisher(data, id): Observable<IResponse> {
    return this.api.putRequest('publishers', id, data).map( (res: IResponse) => {
      return res;
    });
  }
  public updateLength(data): Observable<IResponse> {
    return this.api.postRequest('lengthclass', 'update', data).map( (res: IResponse) => {
      return res;
    });
  }
  public updateCover(data): Observable<IResponse> {
    return this.api.putRequest('bookcover', 'update', data).map( (res: IResponse) => {
      return res;
    });
  }
  public deleteCover(data): Observable<IResponse> {
    return this.api.deleteRequest('bookcover', 'update', data).map( (res: IResponse) => {
      return res;
    });
  }
  public createLength(data): Observable<IResponse> {
    return this.api.postRequest('lengthclass', 'create', data).map( (res: IResponse) => {
      return res;
    });
  }
  public createCover(data): Observable<IResponse> {
    return this.api.postRequest('bookcover', 'create', data).map( (res: IResponse) => {
      return res;
    });
  }
  public createDownload(data): Observable<IResponse> {
    return this.api.postRequest('productdownload', 'add', data).map( (res: IResponse) => {
      return res;
    });
  }
  public updateManufacturer(data): Observable<IResponse> {
    return this.api.putRequest('manufacturer', 'update', data).map( (res: IResponse) => {
      return res;
    });
  }
  public updateStock(data): Observable<IResponse> {
    return this.api.putRequest('stock', 'update', data).map( (res: IResponse) => {
      return res;
    });
  }
  public getWeightClass(vendor_id): Observable<IResponse> {
    return this.api.getRequest('weightclass', 'list/' + vendor_id).map( (res: IResponse) => {
      return res;
    });
  }
  public getLenghtClass(vendor_id): Observable<IResponse> {
    return this.api.getRequest('lengthclass', 'list/' + vendor_id).map( (res: IResponse) => {
      return res;
    });
  }
  public getCustomers(): Observable<IResponse> {
    return this.api.getRequest('customer', 'list').map( (res: IResponse) => {
      return res;
    });
  }
  public getAdministrators(): Observable<IResponse> {
    return this.api.getRequest('adminuser', 'list').map( (res: IResponse) => {
      return res;
    });
  }
  public getUserGroups(): Observable<IResponse> {
    return this.api.getRequest('usergroup', 'list').map( (res: IResponse) => {
      return res;
    });
  }
  createAdmin(data: any): Observable<IResponse> {
    return this.api.postRequest('admin', 'account/create' , data).map((res: IResponse)  => {
      return res ;
    });
  }

  createVAT(data: any): Observable<IResponse> {
    return this.api.postRequest('taxclass', 'create' , data).map((res: IResponse)  => {
      return res ;
    });
  }

  updateAdmin(data: any): Observable<IResponse> {
    return this.api.putRequest('adminuser', 'profile/update' , data).map((res: IResponse)  => {
      return res ;
    });
  }

  updateVAT(data: any): Observable<IResponse> {
    return this.api.putRequest('taxclass', 'update' , data).map((res: IResponse)  => {
      return res ;
    });
  }
  getVATS(): Observable<IResponse> {
    return this.api.getRequest('taxclass', 'list').map((res: IResponse)  => {
      return res ;
    });
  }

  public deleteCategory(category_id): Observable<IResponse> {
    return this.api.deleteRequest('category', 'softdelete/' + category_id ).map( (res: IResponse) => {
      return res;
    });
  }
  public createCoupon(data): Observable<IResponse> {
    return this.api.postRequest('coupon', 'create' , data).map( (res: IResponse) => {
      return res;
    });
  }
  public createVoucher(data): Observable<IResponse> {
    return this.api.postRequest('voucher', 'create' , data).map( (res: IResponse) => {
      return res;
    });
  }
  public createFeaturedProducts(data): Observable<IResponse> {
    return this.api.postRequest('featuredproduct', 'add' , data).map( (res: IResponse) => {
      return res;
    });
  }
  public createOnSalesProducts(data): Observable<IResponse> {
    return this.api.postRequest('onsalesproduct', 'add' , data).map( (res: IResponse) => {
      return res;
    });
  }
  public createBestSellerProducts(data): Observable<IResponse> {
    return this.api.postRequest('bestsellerproduct', 'add' , data).map( (res: IResponse) => {
      return res;
    });
  }
  public attachCouponCategory(data): Observable<IResponse> {
    return this.api.postRequest('couponcategory', null , data).map( (res: IResponse) => {
      return res;
    });
  }
  public attachVoucherCategory(data): Observable<IResponse> {
    return this.api.postRequest('vouchercategory', null , data).map( (res: IResponse) => {
      return res;
    });
  }
  public attachCouponProduct(data): Observable<IResponse> {
    return this.api.postRequest('couponproduct', null , data).map( (res: IResponse) => {
      return res;
    });
  }
  public attachVoucherProduct(data): Observable<IResponse> {
    return this.api.postRequest('voucherproduct', null , data).map( (res: IResponse) => {
      return res;
    });
  }
  public updateCoupon(data): Observable<IResponse> {
    return this.api.putRequest('coupon', 'update' , data).map( (res: IResponse) => {
      return res;
    });
  }
  public updateVoucher(data): Observable<IResponse> {
    return this.api.putRequest('voucher', 'update' , data).map( (res: IResponse) => {
      return res;
    });
  }
  public deleteCoupon(coupon_id): Observable<IResponse> {
    return this.api.deleteRequest('coupon', coupon_id ).map( (res: IResponse) => {
      return res;
    });
  }
  public deleteVoucher(voucher_id): Observable<IResponse> {
    return this.api.deleteRequest('voucher', voucher_id ).map( (res: IResponse) => {
      return res;
    });
  }
  public deleteManufacturer(manufacturer_id): Observable<IResponse> {
    return this.api.deleteRequest('manufacturer', 'softdelete/' + manufacturer_id ).map( (res: IResponse) => {
      return res;
    });
  }
  public deleteProduct(product_id): Observable<IResponse> {
    return this.api.deleteRequest('product', 'softdelete/' + product_id ).map( (res: IResponse) => {
      return res;
    });
  }
  public deletePublisher(id): Observable<IResponse> {
    return this.api.deleteRequest('publishers', id ).map( (res: IResponse) => {
      return res;
    });
  }
  public deleteFeaturedProduct(product): Observable<IResponse> {
    return this.api.postRequest('featuredproduct', 'remove' , product).map( (res: IResponse) => {
      return res;
    });
  }
  public deleteOnSalesProduct(product): Observable<IResponse> {
    return this.api.postRequest('onsalesproduct', 'remove' , product).map( (res: IResponse) => {
      return res;
    });
  }
  public deleteBestSellerProduct(product): Observable<IResponse> {
    return this.api.postRequest('bestsellerproduct', 'remove' , product).map( (res: IResponse) => {
      return res;
    });
  }
  public deleteSubCategory(subcategory_id): Observable<IResponse> {
    return this.api.deleteRequest('subcategory', 'softdelete/' + subcategory_id ).map( (res: IResponse) => {
      return res;
    });
  }

  public getNewOrdersReport(): Observable<IResponse> {
    return this.api.getRequest('report/neworder', 'list').map( (res: IResponse) => {
      return res;
    });
  }
  public getCoupons(): Observable<IResponse> {
    return this.api.getRequest('coupons', null).map( (res: IResponse) => {
      return res;
    });
  }
  public getVouchers(): Observable<IResponse> {
    return this.api.getRequest('voucher', null).map( (res: IResponse) => {
      return res;
    });
  }
  public getLowStockOrdersReport(): Observable<IResponse> {
    return this.api.getRequest('report/lowstock', 'list').map( (res: IResponse) => {
      return res;
    });
  }
  public getSalesByProducts(productId): Observable<IResponse> {
    return this.api.getRequest('report/salesbyproduct', productId).map( (res: IResponse) => {
      return res;
    });
  }
  public getSalesByCustomers(): Observable<IResponse> {
    return this.api.getRequest('report/salesbycustomers', 'list').map( (res: IResponse) => {
      return res;
    });
  }
  public getOutStockOrdersReport(): Observable<IResponse> {
    return this.api.getRequest('report/outofstock', 'list').map( (res: IResponse) => {
      return res;
    });
  }
  public getTransactionReport(vendor_id): Observable<IResponse> {
    return this.api.getRequest('report/transaction', 'list/' + vendor_id).map( (res: IResponse) => {
      return res;
    });
  }
}
