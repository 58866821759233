import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../../services/authService/auth.service";
import {IResponse} from "../../../interfaces/iresponse";
import {UserService} from "../../../services/apiCalls/userService/user.service";
import {BootstrapNotifyService} from "../../../services/bootstrap-notify/bootstrap-notify.service";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  breadCrumb: any;
  public password = {
    old_password: null,
    new_password: null,
    new_password_confirmation: null
  };
  isUpdatingP: boolean;
  constructor(private alertService: BootstrapNotifyService,
              private userService: UserService,
              private authService: AuthService) { }

  ngOnInit() {
    this.breadCrumb  = {
      name: 'Change Password',
      parent: 'Settings',
      subLink: null
    };
    this.isUpdatingP = false;
  }
  public updatePassword() {
    this.isUpdatingP = true;
    console.log('Password ', this.password);
    if (!this.password.old_password) {
      this.alertService.error('You must enter your old password!');
      this.isUpdatingP = false;
      return;
    } else if (!this.password.new_password) {
      this.alertService.error('You must enter new password!');
      this.isUpdatingP = false;
      return;
    } else if (!this.password.new_password_confirmation) {
      this.alertService.error('You must confirm new password!');
      this.isUpdatingP = false;
      return;
    } else if (this.password.new_password_confirmation !== this.password.new_password) {
      this.alertService.error('New password must match old password!');
      this.isUpdatingP = false;
      return;
    } else if (this.password.new_password.length < 7) {
      this.alertService.error('New password too short!');
      this.isUpdatingP = false;
      return;
    } else {
      this.userService.updatePassword(this.password).subscribe((res: IResponse) => {
        console.log('Password Update ', res);
        this.isUpdatingP = false;
        this.password = {
          old_password: null,
          new_password: null,
          new_password_confirmation: null
        };
        this.alertService.success('Password updated successfully');
        this.authService.logUserOut();
      }, error => {
        console.log('Error ', error);
        this.isUpdatingP = false;
        this.alertService.error(error.error.message || 'Unable to update password');
      });
    }
  }
}
