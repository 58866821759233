import { Component, OnInit } from '@angular/core';
import {UtilService} from "../../services/utilService/util.service";
import {SuperAdminService} from "../../services/apiCalls/superAdminService/super-admin.service";
import {BootstrapNotifyService} from "../../services/bootstrap-notify/bootstrap-notify.service";
import {IResponse} from "../../interfaces/iresponse";

@Component({
  selector: 'app-coupon-management',
  templateUrl: './coupon-management.component.html',
  styleUrls: ['./coupon-management.component.css']
})
export class CouponManagementComponent implements OnInit {
  breadCrumb: any;
  coupons = [];
  categories = [];
  products = [];
  currentUser = null;
  loading = false;
  modal = {
    title: 'Create Coupon',
    btnText: 'SAVE'
  };
  coupon = {
    name: null,
    type: 'percentage',
    status: 1,
    logged: 1,
    discount: 0,
    shipping: 0,
    total: 0,
    uses_total: 0,
    uses_customer: 0,
    date_start: null,
    date_end: null,
  };
  activeCoupon = null;
  loaders = {
    saving: false
  };
  catToAttach = null;
  prodToAttach = null;
  toProduct = false;
  toCategory = false;
  constructor(private utilService: UtilService,
              private superAdminService: SuperAdminService,
              private alertService: BootstrapNotifyService) { }

  ngOnInit() {
    this.currentUser = this.utilService.getAuthUser();
    this.breadCrumb  = {
      name: 'Coupon',
      parent: 'Products',
      subLink: null
    };
    this.getCoupons();
    this.getProducts();
    this.getCategories();
  }
  getCoupons() {
    this.loading = true;
    this.superAdminService.getCoupons()
      .subscribe((res: IResponse) => {
        this.coupons = res.data;
        console.log('RES copons ', res);
        this.utilService.startDatatable('coupons-list');
        this.alertService.success(res.message || 'Coupons listed successfully!');
        this.loading = false;
      }, error => {
        console.log({error});
        this.loading = false;
        this.utilService.startDatatable('coupons-list');
        this.alertService.error(error.error.message || 'Unable to list coupons!');
      });
  }
  openDialog(id) {
    this.modal = {
      title: 'Create Coupon',
      btnText: 'SAVE'
    };
    this.activeCoupon = null;
    this.resetForm();
    this.utilService.openModal(id);
    this.initPicker();
  }
  resetForm() {
    this.coupon = {
      name: null,
      type: 'percentage',
      status: 1,
      logged: 1,
      discount: 0,
      shipping: 0,
      total: 0,
      uses_total: 0,
      uses_customer: 0,
      date_start: null,
      date_end: null,
    };
  }
  deleteCoupon(coupon) {
    this.superAdminService.deleteCoupon(coupon.coupon_id).subscribe((res: IResponse) => {
      this.alertService.success(res.message || 'Coupon deleted successfully!');
      this.getCoupons();
    }, error => {
      console.log('Error ', error);
      this.alertService.error(error.error.message || 'Unable to delete coupon');
    });
  }
  public triggerEdit(coupon) {
    this.activeCoupon = JSON.parse(JSON.stringify(coupon));
    this.resetForm();
    this.coupon = this.activeCoupon;
    this.modal = {
      title: 'Update Coupon',
      btnText: 'UPDATE'
    };
    this.utilService.openModal('createCoupon');
    this.initPicker();
  }
  public createCoupon() {
    console.log('Coupon ', this.coupon);
    this.loaders.saving = true;
    if (!this.coupon.name) {
      this.alertService.info('Coupon name is Required');
      this.loaders.saving = false;
    } else if (!this.coupon.discount) {
      this.alertService.info('Coupon discount is Required');
      this.loaders.saving = false;
    } else if (!this.coupon.total) {
      this.alertService.info('Minimum order amount is required!');
      this.loaders.saving = false;
    } else {
      if (this.activeCoupon) {
        this.updateCoupon();
      } else {
        this.superAdminService.createCoupon(this.coupon).subscribe((response: IResponse) => {
          console.log('Response', response);
          this.loaders.saving = false;
          this.resetForm();
          this.getCoupons();
          this.utilService.closeModal('createCoupon');
          this.alertService.info(response.message || 'Coupon created successfully!');
        }, error => {
          this.alertService.error(error.error.message || 'Unable to create coupon!', 'right');
          this.loaders.saving = false;
          console.info('Error => ', error);
        });
      }
    }

  }
  public updateCoupon() {
    console.log('Active Coupon ', this.activeCoupon);
    this.superAdminService.updateCoupon(this.activeCoupon).subscribe((response: IResponse) => {
      console.log('Response', response);
      this.loaders.saving = false;
      this.resetForm();
      this.getCoupons();
      this.utilService.closeModal('createCoupon');
      this.alertService.info(response.message || 'Coupon updated successfully!');
    }, error => {
      this.alertService.error(error.error.message || 'Unable to update coupon!', 'right');
      this.loaders.saving = false;
      console.info('Error => ', error);
    });
  }
  initPicker() {
    setTimeout(() => {
      (<any>$('.datepicker')).datetimepicker({
        icons: {
          time: 'fa fa-clock-o',
          date: 'fa fa-calendar',
          up: 'fa fa-chevron-up',
          down: 'fa fa-chevron-down',
          previous: 'fa fa-chevron-left',
          next: 'fa fa-chevron-right',
          today: 'fa fa-screenshot',
          clear: 'fa fa-trash',
          close: 'fa fa-remove'
        },
        format: 'YYYY-MM-DD'
      });
    }, 400);
  }

  public updateField(id) {
    setTimeout(() => {
      const val = $('#' + id).val();
      this.coupon[id] = val;
    }, 1000);
  }

  getCategories() {
    this.superAdminService.getCategories()
      .subscribe((res: IResponse) => {
        this.categories = res.data;
      }, error => {
        console.log('Error ', error);
      });
  }
  getProducts() {
    this.superAdminService.getProducts()
      .subscribe((res: IResponse) => {
        this.products = res.data;
      }, error => {
        console.log('Error ', error);
      });
  }

  showProduct(coupon) {
    this.activeCoupon = coupon;
    this.toProduct = true;
    this.toCategory = false;
    this.utilService.openModal('attachCoupon');
  }
  showCategories(coupon) {
    this.activeCoupon = coupon;
    this.toProduct = false;
    this.toCategory = true;
    this.utilService.openModal('attachCoupon');
  }
  closeAttach() {
    this.utilService.closeModal('attachCoupon');
    this.activeCoupon = null;
    this.toProduct = false;
    this.toCategory = false;
  }
  saveAttachment() {
    this.loaders.saving = true;
    if (this.toCategory) {
      this.superAdminService.attachCouponCategory({category_id: this.catToAttach, coupon_id: this.activeCoupon.coupon_id})
        .subscribe((res: IResponse) => {
        this.alertService.success(res.message || 'Coupon attached successfully');
        this.loaders.saving = false;
        console.log({res});
          this.closeAttach();
        }, error => {
        this.alertService.error(error.error.message || 'Unable to attach coupon to category');
        this.loaders.saving = false;
        console.log({error});
          this.closeAttach();
        });
    } else if (this.toProduct) {
      this.superAdminService.attachCouponProduct({product_id: this.prodToAttach, coupon_id: this.activeCoupon.coupon_id})
        .subscribe((res: IResponse) => {
          this.alertService.success(res.message || 'Coupon attached successfully');
          this.loaders.saving = false;
          console.log({res});
          this.closeAttach();
        }, error => {
          this.alertService.error(error.error.message || 'Unable to attach coupon to product');
          this.loaders.saving = false;
          console.log({error});
          this.closeAttach();
        });
    } else {
      this.alertService.info('Unable to detect attachment type!');
      this.closeAttach();
    }
  }
}
