import { Component, OnInit } from '@angular/core';
import {UtilService} from "../../services/utilService/util.service";
import {UserService} from "../../services/apiCalls/userService/user.service";
import {BootstrapNotifyService} from "../../services/bootstrap-notify/bootstrap-notify.service";
import {SuperAdminService} from "../../services/apiCalls/superAdminService/super-admin.service";
import {IResponse} from "../../interfaces/iresponse";

@Component({
  selector: 'app-vendor-new-order-report',
  templateUrl: './vendor-new-order-report.component.html',
  styleUrls: ['./vendor-new-order-report.component.css']
})
export class VendorNewOrderReportComponent implements OnInit {
  breadCrumb: any;
  orders = [];
  currentUser = null;
  loading = false;
  constructor(private utilService: UtilService,
              private userService: UserService,
              private alertService: BootstrapNotifyService) { }

  ngOnInit() {
    this.currentUser = this.utilService.getAuthUser();
    this.breadCrumb  = {
      name: 'New Order',
      parent: 'Report',
      subLink: null
    };
    this.getOrders();
  }
  getOrders() {
    this.loading = true;
    this.userService.getNewOrdersReport(this.currentUser.vendor_id)
      .subscribe((res: IResponse) => {
      this.orders = res.data;
      this.alertService.success(res.message || 'New order report fetched!');
      this.loading = false;
      }, error => {
      console.log({error});
        this.loading = false;
        this.alertService.error(error.error.message || 'Unable to list new orders report!');
      });
  }

}
