import { Component, OnInit } from '@angular/core';
import { environment as ENV } from '../../../environments/environment';
import {BootstrapNotifyService} from '../../services/bootstrap-notify/bootstrap-notify.service';
import {UserService} from '../../services/apiCalls/userService/user.service';
import {IResponse} from '../../interfaces/iresponse';
import {NavigatorService} from '../../services/navigatorService/navigator.service';
import * as JWT_DECODE from 'jwt-decode';
import {CacheService} from '../../services/cacheService/cache.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../services/authService/auth.service';
import {EventsService} from "../../services/eventServices/event.service";

@Component({
  selector: 'app-vendor-register',
  templateUrl: './vendor-register.component.html',
  styleUrls: ['./vendor-register.component.css']
})
export class VendorRegisterComponent implements OnInit {
  public EMAIL_VALIDATION: any = ENV.EMAIL_VALIDATION;
  public vendor = {
    lastname: null,
    firstname: null,
    telephonenumber: null,
    nickname: null,
    company: null,
    description: null,
    country_id: 156,
    zone_id: 2412,
    address: null,
    city: null,
    postcode: null,
    username: null,
    email: null,
    password: null
  };
  changeCountry = false;
  public pageTitle = 'User Information';
  public userDetails: any;
  loaders = {
    login: false,
    showResetLink: false
  };
  public allCountries: any[] = [];
  public allZones: any[] = [];

  constructor(private bootstrapNotify: BootstrapNotifyService,
              private userService: UserService,
              private cacheService: CacheService,
              private navigateService: NavigatorService,
              private router: Router,
              private eventService: EventsService,
              private authService: AuthService,
              private activeRoute: ActivatedRoute) {
    this.authService.logOut();
  }

  ngOnInit(): void {
    this.userDetails = null;
    this.getCountries();
    this.getZones(156);
  }

  public signupVendor() {
    this.loaders.login = true;
    this.cacheService.deleteSession(ENV.TOKEN);
    this.cacheService.deleteStorage(ENV.TOKEN);
    this.cacheService.deleteStorage(ENV.USERTOKEN);
    this.cacheService.deleteSession(ENV.USERTOKEN);
    if (!this.vendor.email) {
      this.bootstrapNotify.info('Vendor Email is Required');
      this.loaders.login = false;
    } else if (!this.vendor.telephonenumber) {
      this.bootstrapNotify.info('Vendor Phone Number is Required');
      this.loaders.login = false;
    } else if (!this.vendor.firstname) {
      this.bootstrapNotify.info('First Name is Required');
      this.loaders.login = false;
    } else if (!this.vendor.lastname) {
      this.bootstrapNotify.info('Last Name is Required');
      this.loaders.login = false;
    } else if (!this.vendor.username) {
      this.bootstrapNotify.info('Username is Required');
      this.loaders.login = false;
    } else if (!this.vendor.password) {
      this.bootstrapNotify.info('Enter a secure password');
      this.loaders.login = false;
    }  else if (!this.vendor.company) {
      this.bootstrapNotify.info('Company name is required');
      this.loaders.login = false;
    } else if (!this.vendor.zone_id) {
      this.bootstrapNotify.info('Select your zone!');
      this.loaders.login = false;
    } else if (!this.vendor.country_id) {
      this.bootstrapNotify.info('Select country!');
      this.loaders.login = false;
    } else {
      this.userService.createVendor(this.vendor).subscribe((response: IResponse) => {
        console.log('Response', response);
        this.loaders.login = false;
        this.resetSignup();
        // this.navigateService.navigateUrl(`/login`);
        this.bootstrapNotify.success('Registration successful, please login');
        this.eventService.broadcast('NEW_LOGIN');
        this.authService.handleRedirect(response);
      }, error => {
        this.loaders.login = false;
        this.bootstrapNotify.advance(error.error.message || 'Unable to register at this moment, please try again', 'right');
        console.info('Error => ', error);
      });
    }
  }

  public movePage(action, position, e) {
    e.preventDefault();
    $('.step-').addClass('d-none');
    if (position < 4) {
      this.pageTitle = 'User Information';
    } else {
      this.pageTitle = 'Company Information';
    }
    $('.step-' + position).removeClass('d-none');
  }

  public resetSignup() {
    this.vendor = {
      lastname: null,
      firstname: null,
      telephonenumber: null,
      nickname: null,
      company: null,
      description: null,
      country_id: 156,
      zone_id: 2412,
      address: null,
      city: null,
      postcode: null,
      username: null,
      email: null,
      password: null
    };
  }

  public getCountries() {
    this.userService.getAllCountries().subscribe(res => {
      console.log('Resp ', res);
      this.allCountries = res.data;
    }, error => {
      console.log('Error ', error);
      this.getCountries();
    });
  }

  public getZones(countryId = this.vendor.country_id) {
    this.changeCountry = false;
    this.userService.getCountryZones(countryId).subscribe(res => {
      console.log('Resp ', res);
      this.allZones = res.data;
      this.changeCountry = true;
    }, error => {
      console.log('Error ', error);
    });
  }
}
