import {AfterViewInit, Component, OnInit} from '@angular/core';
import {environment as ENV} from '../../../../../environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import {forEach} from '@angular/router/src/utils/collection';
import {UtilService} from '../../../../services/utilService/util.service';
import {UserService} from '../../../../services/apiCalls/userService/user.service';
import {AuthService} from '../../../../services/authService/auth.service';
import {EventsService} from '../../../../services/eventServices/event.service';
import {BootstrapNotifyService} from '../../../../services/bootstrap-notify/bootstrap-notify.service';
import {IResponse} from '../../../../interfaces/iresponse';

declare const $: any;
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit, AfterViewInit {
  public currentUser: any;
  public Roles: any = [];
  public paymentChannels: any = [];
  public wallet: any;
  public fundWallet = {
    payment_channel_id: null,
    amount: 0.0,
    response_url: null
  };
  public loaders = {
    loading: false,
    saving: false
  };
  static updateActiveBar(url) {
    setTimeout(() => {
      console.info('URL : ', url);
      let oldUrl;
      url = url.split('?')[0];
      oldUrl = 'dashboard';
      $('.nav-link-special').removeClass('active');
      $(`#${url}`).addClass('active');
      if ($(`#${url}`).hasClass('active')) {
        oldUrl = url;
      } else {
        $(`#${oldUrl}`).addClass('active');
      }
    }, 500);
  }
  constructor(
    private route: Router,
    private utilService: UtilService,
    private userService: UserService,
    private authService: AuthService,
    private eventService: EventsService,
    private notifyService: BootstrapNotifyService
  ) {
    this.wallet = 0.0;
    this.eventService.on('FUND_WALLET_NOW', () => {
      // this.triggerFundWallet();
    });
    this.eventService.on('RELOAD_HISTORY', () => {
      // this.getWallet(this.currentUser.id);
    });
  }
  ngOnInit() {
   // this.setRole();
   // this.getAuthUser();
   // this.getPaymentChannels();
  }

  public getAuthUser() {
    this.currentUser = this.utilService.getAuthUser();
    console.info('Current User', this.currentUser);
    this.getWallet(this.currentUser.id);
  }
  public getWallet(userId) {
    this.userService.getWallet(userId).subscribe((res: IResponse) => {
      console.log('Wallet Data ', res);
      this.wallet = res.data.balance || 0;
    }, error => {    });
  }
  setRole() {
    this.Roles = [];
    const user: any = JSON.parse(sessionStorage.getItem(ENV.USERTOKEN));
    this.Roles = this.utilService.setRoles();
    this.currentUser =  user;
  }
  public checkPrivilege(users) {
    for (const user of users) {
      if (this.Roles.includes(user)) {
        return true;
      }
    }
    return false;
  }
  ngAfterViewInit(): void {
    const routes = this.route.url.split('/');
    SidebarComponent.updateActiveBar(routes[routes.length - 1]);
    const navLink = document.querySelectorAll('.nav-link-special');
    navLink.forEach((el) => {
      // console.info('Ele ', el);
      el.addEventListener('click', (e) => {
        $('.navLinks').removeClass('active');
        $('.childNavDropDown').removeClass('in');
        const id = el['id'];
        // console.info('ellll ', el['id']);
        if (id.includes('#') || !id) {return false; }
        $(`#${id}`).addClass('active');
        $(`#${id} > ul`).addClass('in');

      });
    });
  }

  public logOut() {
    this.userService.logOut().subscribe(() => {
      this.authService.logOut();
    });
    this.authService.logOut();
    this.route.navigate(['/']);
  }
  public forceReload(data) {
    this.eventService.broadcast('RERENDER_COMPONENT', data);
  }
  public triggerFundWallet() {
    this.getPaymentChannels();
    this.utilService.openModal('fundWallet');
  }
  public getPaymentChannels() {
    this.userService.getPaymentChannels().subscribe((res: IResponse) => {
      this.paymentChannels = res.data.filter(d => d.can_fund);
      this.paymentChannels = this.paymentChannels.filter(channel => channel.is_active === true && channel.is_editable);
    }, error => {
      console.log('Unable to get payment Channels');
    });
  }
  public proceedToFund() {
    if(!this.fundWallet.payment_channel_id) {
      return this.notifyService.warning('Select payment channel for this transaction!');
    } else if(this.fundWallet.amount < 0 || this.fundWallet.amount === 0) {
      return this.notifyService.warning('Invalid Amount');
    } else {
      this.loaders.saving = true;
      console.log('URL ', window.location);
      this.fundWallet.response_url = `${window.location.origin}/user/wallet-history`;
      this.userService.fundWalletInit(this.fundWallet).subscribe((res: IResponse) => {
        this.loaders.saving = false;
        console.log('Walleting ', res);
        this.utilService.closeModal('fundWallet');
        this.notifyService.success(res.message || 'Wallet funding initiated successfully');
        this.proceedToGateway(res.data);
        this.eventService.broadcast('RELOAD_HISTORY');
      }, error => {
        this.loaders.saving = false;
        this.notifyService.error(error.error.message || 'Unable to fund wallet at this moment, please try again later!', 'right');
      });
    }
  }
  public proceedToGateway(data) {
    const generateBody = data.payload;
    let URL = `${data.payment_link}?merchant_id=${generateBody['merchant_id']}
    &product_id=${generateBody['product_id']}&product_description=${generateBody['product_description']}
    &amount=${generateBody['amount']}&currency=${generateBody['currency']}
    &transaction_id=${generateBody['transaction_id']}&response_url=${generateBody['response_url']}
    &email=${generateBody['email']}&hash=${generateBody['hash']}`;
    window.open(URL, '_blank');
  }
  public validateAmount() {
    if(Math.sign(this.fundWallet.amount) < 0) {
       this.fundWallet.amount = this.fundWallet.amount * -1;
    } else {
      return;
    }
  }
}
