import { Component, OnInit } from '@angular/core';
import {IResponse} from "../../../interfaces/iresponse";
import {SuperAdminService} from "../../../services/apiCalls/superAdminService/super-admin.service";
import {UtilService} from "../../../services/utilService/util.service";
import {BootstrapNotifyService} from "../../../services/bootstrap-notify/bootstrap-notify.service";
import {isNullOrUndefined} from "util";

@Component({
  selector: 'app-manage-publishers',
  templateUrl: './manage-publishers.component.html',
  styleUrls: ['./manage-publishers.component.css']
})
export class ManagePublishersComponent implements OnInit {
  breadCrumb: any;
  publishers = [];
  vendors = [];

  loaders = {
    publisher: false,
    saving: false
  };
  publisherModal = {
    title: 'Create Publisher',
    btnText: 'SAVE',
  };

  publisher = {
    name: null,
    sort_order: Math.floor((Math.random() * 100)),
    vendor_id: null
  };
  activePublisher = null;
  constructor(private superAdminService: SuperAdminService,
              private utilService: UtilService,
              private alertService: BootstrapNotifyService) {
    this.getVendors();
  }

  ngOnInit() {
    this.breadCrumb  = {
      name: 'Manage Publishers',
      parent: 'Configuration',
      subLink: null
    };
    this.getPublishers();
  }

  public getPublishers() {
    this.loaders.publisher = true;
    this.superAdminService.getPublishers().subscribe((res: IResponse) => {
      console.log('Res ', res);
      this.loaders.publisher = false;
      this.publishers = res.data;
      this.utilService.responsiveTable('list', false);
    }, error => {
      console.log('Error ', error);
      this.loaders.publisher = false;
      this.alertService.error(error.error.message || 'Unable to list publishers');
      this.utilService.responsiveTable('list', false);
    } );
  }


  openDialog(id, section) {
    switch (section) {
      case 'publisher': {
        this.publisherModal = {
          title: 'Create Publisher',
          btnText: 'SAVE'
        };
        this.activePublisher = null;
        this.resetPublisher();
        this.utilService.openModal('createPublisher');
        break;
      }
    }
  }

  public getVendors() {
    this.superAdminService.getVendors().subscribe((res: IResponse) => {
      console.log('Res ', res);
      this.vendors = res.data;
    }, error => {
      console.log('Error ', error);
      this.alertService.error(error.error.message || 'Unable to list vendors');
    } );
  }
  selectFiles(id) {
    $('#' + id).trigger('click');
  }
  resetPublisher() {
    this.publisher = {
      name: null,
      sort_order: Math.floor((Math.random() * 100)),
      vendor_id: null
    };
  }

  public processSelectedFile(event, object) {
    const selectedFiles =  event.target.files;
    const reader = new FileReader();
    const file = selectedFiles[0];
    if (!isNullOrUndefined(file)) {
      reader.onloadend = () => {
        object.imageupload = reader.result;
      };
      reader.readAsDataURL(file);
    }
  }

  createPublisher() {
    console.log('Create Publishers', this.publisher);
    if (!this.publisher.name) {
      return this.alertService.error('Publisher name is required');
    } else if (this.activePublisher) {
      this.loaders.saving = true;
      this.updatePublisher();
    } else {
      this.loaders.saving = true;
      this.superAdminService.createPublisher(this.publisher).subscribe((res: IResponse) => {
        this.getPublishers();
        this.alertService.success(res.message || 'Publisher created successfully!');
        this.loaders.saving = false;
        this.utilService.closeModal('createPublisher');
        this.resetPublisher();
      }, error => {
        this.loaders.saving = false;
        this.alertService.error(error.error.message || 'Unable to create publisher!');
        console.log({error});
      });
    }
  }

  updatePublisher() {
    this.superAdminService.updatePublisher(this.activePublisher, this.activePublisher.publisher_id).subscribe((res: IResponse) => {
      this.getPublishers();
      this.alertService.success(res.message || 'Publisher updated successfully!');
      this.loaders.saving = false;
      this.utilService.closeModal('createPublisher');
      this.resetPublisher();
    }, error => {
      this.loaders.saving = false;
      this.alertService.error(error.error.message || 'Unable to update publisher!');
      console.log({error});
    });
  }
  triggerEdit(data, id) {
    switch (id) {
      case 'publisher': {
        this.activePublisher = JSON.parse(JSON.stringify(data));
        this.resetPublisher();
        this.publisher = this.activePublisher;
        this.publisherModal = {
          title: 'Update Publisher',
          btnText: 'UPDATE'
        };
        this.utilService.openModal('createPublisher');
        break;
      }
    }
  }
  triggerDelete(data, id) {
    switch (id) {
      case 'publisher': {
        this.utilService.confirmAction(() => {
          this.superAdminService.deletePublisher(data.publisher_id)
            .subscribe((res: IResponse) => {
              console.log('Res ', res);
              this.getPublishers();
            }, error => {
              console.log('Errro ', error);
            });
        });
        break;
      }
    }
  }

}
