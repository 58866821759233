import { Component, OnInit } from '@angular/core';
import {SuperAdminService} from '../../services/apiCalls/superAdminService/super-admin.service';
import {BootstrapNotifyService} from '../../services/bootstrap-notify/bootstrap-notify.service';
import {IResponse} from '../../interfaces/iresponse';
import {UtilService} from '../../services/utilService/util.service';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit {
  breadCrumb: any;
  customers: any[] = [];
  loaders = {
    loading: false,
    loadingData: false,
    changeOccured: false
  };
  imagePath = null;
  constructor(private superAdminService: SuperAdminService,
              private utilService: UtilService,
              private alertService: BootstrapNotifyService) { }
  ngOnInit() {
    this.breadCrumb  = {
      name: 'Customer Management',
      parent: 'Users',
      subLink: null
    };
    this.getCustomers();
  }
  getCustomers() {
    this.loaders.loadingData = true;
    this.loaders.changeOccured = false;
    this.superAdminService.getCustomers().subscribe((res: IResponse) => {
      this.loaders.loadingData = false;
      this.loaders.changeOccured = true;
      this.customers = res.data;
      this.imagePath = res.imagepath;
      console.log('RESponse ', res);
      this.utilService.startDatatable('customers-list');
      this.alertService.success(res.message || 'Customers listed successfully');
    }, error => {
      console.log('Errors ', error);
      this.alertService.error(error && error.error && error.error.message || 'Unable to list customers');
      this.utilService.startDatatable('customers-list');
      this.loaders.loadingData = false;
      this.loaders.changeOccured = true;
    });

  }

  public triggerEdit(vendor) {
    /*this.activeVendor = this.vendor = JSON.parse(JSON.stringify(vendor));
    this.resetForm();
    this.category = this.activeVendor;
    this.modal = {
      title: 'Update Vendor',
      btnText: 'UPDATE'
    };
    this.utilService.openModal('createVendor');*/
  }


}
