import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notification-management',
  templateUrl: './notification-management.component.html',
  styleUrls: ['./notification-management.component.css']
})
export class NotificationManagementComponent implements OnInit {
  breadCrumb: any;
  constructor() { }

  ngOnInit() {
    this.breadCrumb  = {
      name: 'Notification',
      parent: 'Settings',
      subLink: null
    };
  }

}
