import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-seo-management',
  templateUrl: './seo-management.component.html',
  styleUrls: ['./seo-management.component.css']
})
export class SeoManagementComponent implements OnInit {
  breadCrumb: any;
  constructor() { }

  ngOnInit() {
    this.breadCrumb  = {
      name: 'Seo Management',
      parent: 'Settings',
      subLink: null
    };
  }

}
