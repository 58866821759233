import { Component, OnInit } from '@angular/core';
import {UtilService} from '../../services/utilService/util.service';
import {SuperAdminService} from '../../services/apiCalls/superAdminService/super-admin.service';
import { environment as ENV } from '../../../environments/environment';
import {IResponse} from '../../interfaces/iresponse';
import {UserService} from '../../services/apiCalls/userService/user.service';
import {BootstrapNotifyService} from '../../services/bootstrap-notify/bootstrap-notify.service';

@Component({
  selector: 'app-product-management',
  templateUrl: './product-management.component.html',
  styleUrls: ['./product-management.component.css']
})
export class ProductManagementComponent implements OnInit {
  breadCrumb: any;
  public EMAIL_VALIDATION: any =  ENV.EMAIL_VALIDATION;
  loaders = {
    saving: false,
    loadingImg: false
  };
  coupons = [];
  VATS = [];
  bookCovers = [];
  categories: any[] = [];
  products: any[] = [];
  vendors: any[] = [];
  manufacturers: any[] = [];
  downloadables: any[] = [];
  stockLists: any[] = [];
  weightClass: any[] = [];
  lenghtClass: any[] = [];
  activeProduct = null;
  product =  {
    product_name: null,
    description: null,
    weight: null,
    latest_arrival: 0,
    best_seller: 0,
    bookcover_id: undefined,
    location: null,
    meta_title: null,
    series: null,
    meta_description: null,
    meta_keyword: null,
    image: null,
    _image: null,
    images: null,
    sort_order: Math.floor((Math.random() * 100)),
    category_id: null,
    sku: null,
    isbn: null,
    mpn: null,
    manufacturer_id: null,
    publisher_id: null,
    imageupload: [],
    weightclass_id: 1,
    length: 1,
    lengthclass_id: 1,
    taxclass_id: 1,
    quantity: 1,
    purchase_price: 0.0,
    selling_price: 0.0,
    vendor_id: null
  };
  publishers = [];
  loadingData = false;
  changeOccured = true;
  imagePATH = null;
  imageHolder = null;
  public modal = {
    title: 'Create Product',
    btnText: 'SAVE'
  };
  constructor(private utilService: UtilService,
              private userService: UserService,
              private alertService: BootstrapNotifyService,
              private bootstrapNotify: BootstrapNotifyService,
              private superAdminService: SuperAdminService) {
  }

  ngOnInit() {
    this.breadCrumb  = {
      name: 'Product',
      parent: 'Home',
      subLink: null
    };
    this.getBookCovers();
    this.getProducts();
    this.getPublishers();
    this.getVendors();
    this.getManufacturers();
    this.getCategories();
    this.getVATS();
  }

  openDialog(id) {
    this.modal = {
      title: 'Create Product',
      btnText: 'SAVE'
    };
    this.activeProduct = null;
    this.resetForm();
    this.utilService.openModal(id);
  }

  public getPublishers() {
    this.superAdminService.getPublishers().subscribe((res: IResponse) => {
      console.log('Res ', res);
      this.publishers = res.data;
      this.utilService.responsiveTable('list', false);
    }, error => {
      console.log('Error ', error);
      this.alertService.error(error.error.message || 'Unable to list publishers');
      this.utilService.responsiveTable('list', false);
    } );
  }
  getProducts() {
    this.loadingData = true;
    this.changeOccured = false;
    this.superAdminService.getProducts()
      .subscribe((res: IResponse) => {
        console.log('Response ', res);
        this.products = res.data;
        this.utilService.startDatatable('products-list');
        this.loadingData = false;
        this.changeOccured = true;
      }, error => {
        this.utilService.startDatatable('products-list');
        this.loadingData = false;
        this.changeOccured = true;
        console.log('Error ', error);
      });
  }
  getBookCovers() {
    this.superAdminService.getBookCovers()
      .subscribe((res: IResponse) => {
        console.log('Response ', res);
        this.bookCovers = res.data;
      }, error => {
        console.log('Error ', error);
      });
  }

  public deleteProduct(product) {
    this.utilService.confirmAction(() => {
      this.superAdminService.deleteProduct(product.product_id)
        .subscribe((res: IResponse) => {
          console.log('Res ', res);
          this.getProducts();
        }, error => {
          console.log('Errro ', error);

        });
    });
  }
  public triggerEdit(_product) {
    const product = JSON.parse(JSON.stringify(_product));
    this.activeProduct = product.product;

    this.resetForm();
    this.product = this.activeProduct;
    this.product.images = product.images;
    this.product.selling_price = product.stock.selling_price;
    this.product.category_id = product.categories[0].category_id;
    this.product.imageupload = [];
    this.product.purchase_price = product.stock.purchase_price;
    this.product.taxclass_id = product.stock.taxclass_id;
    this.product.quantity = product.stock.quantity;
    this.product.location = product.stock.location;
    console.log('Product ', this.product);
    this.modal = {
      title: 'Update Product',
      btnText: 'UPDATE'
    };
    this.utilService.openModal('createProduct');
  }


  public createProduct() {
    console.log('Product ', this.product);
    this.loaders.saving = true;
    if (!this.product.product_name) {
      this.bootstrapNotify.info('Product name is Required');
      this.loaders.saving = false;
    } else if (!this.product.description) {
      this.bootstrapNotify.info('Product description is Required');
      this.loaders.saving = false;
    } else {
      if (this.activeProduct) {
        if (!this.product.images.length && !this.product.imageupload.length) {
          this.bootstrapNotify.info('Display image is Required');
          this.loaders.saving = false;
          return;
        }
        this.updateProduct();
      } else {
        if (!this.product.imageupload.length) {
          this.bootstrapNotify.info('Display image is Required');
          this.loaders.saving = false;
          return;
        }
        this.superAdminService.createProduct(this.product).subscribe((response: IResponse) => {
          console.log('Response', response);
          this.loaders.saving = false;
          this.resetForm();
          this.getProducts();
          this.utilService.closeModal('createProduct');
          this.bootstrapNotify.info(response.message || 'Product created successfully!');
        }, error => {
          this.bootstrapNotify.error(error.error.message || 'Unable to create product!', 'right');
          this.loaders.saving = false;
          console.info('Error => ', error);
        });
      }
    }

  }
  public updateProduct() {
    console.log('Active Product ', this.activeProduct);
    this.superAdminService.updateProduct(this.activeProduct, this.activeProduct.product_id).subscribe((response: IResponse) => {
      console.log('Response', response);
      this.loaders.saving = false;
      this.resetForm();
      this.getProducts();
      this.utilService.closeModal('createProduct');
      this.bootstrapNotify.success(response.message || 'Product updated successfully!');
    }, error => {
      this.bootstrapNotify.error(error.error.message || 'Unable to update subproduct!', 'right');
      this.loaders.saving = false;
      console.info('Error => ', error);
    });
  }
  public resetForm() {
    this.product =  {
      product_name: null,
      description: null,
      weight: null,
      meta_title: null,
      images: null,
      series: null,
      latest_arrival: 0,
      best_seller: 1,
      bookcover_id: undefined,
      _image:  null,
      location: null,
      meta_description: null,
      meta_keyword: null,
      image: null,
      sort_order: Math.floor((Math.random() * 100)),
      category_id: null,
      sku: null,
      isbn: null,
      mpn: null,
      manufacturer_id: null,
      publisher_id: null,
      imageupload: [],
      weightclass_id: 1,
      taxclass_id: 1,
      length: 1,
      lengthclass_id: 1,
      quantity: 1,
      purchase_price: 0.0,
      selling_price: 0.0,
      vendor_id: null
    };
  }


  getVATS() {
    this.superAdminService.getVATS()
      .subscribe((res: IResponse) => {
        console.log('Response ', res);
        this.VATS = res.data;
      }, error => {
        console.log('Error ', error);
      });
  }

  public processSelectedFile(event) {
    const selectedFiles =  event.target.files;
    for (const i of selectedFiles) {
      this.getBase64(i).then((res) => {
        this.product.imageupload.push(res);
        console.log('Image s _', this.product.imageupload);
      });
    }
  }
  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  public getWeightClass(vendor_id) {
    this.superAdminService.getWeightClass(vendor_id).subscribe((res: IResponse) => {
      console.log('Res ', res);
      this.weightClass = res.data;
    }, error => {
      console.log('Error ', error);
      this.alertService.error(error.error.message || 'Unable to list weight classes');
    } );
  }

  public getVendors() {
    this.superAdminService.getVendors().subscribe((res: IResponse) => {
      console.log('Res ', res);
      this.vendors = res.data;
      this.getWeightClass(this.vendors[0].vendor_id);
      this.getLengthClass(this.vendors[0].vendor_id);
    }, error => {
      console.log('Error ', error);
      this.alertService.error(error.error.message || 'Unable to list vendors');
    } );
  }

  public getLengthClass(vendor_id) {
    this.superAdminService.getLenghtClass(vendor_id).subscribe((res: IResponse) => {
      console.log('Res ', res);
      this.lenghtClass = res.data;
    }, error => {
      console.log('Error ', error);
      this.alertService.error(error.error.message || 'Unable to list length classes');
    } );
  }

  public getManufacturers() {
    this.superAdminService.getManufacturers().subscribe((res: IResponse) => {
      console.log('Res ', res);
      this.manufacturers = res.data;
    }, error => {
      console.log('Error ', error);
      this.alertService.error(error.error.message || 'Unable to list manufacturers');
    } );
  }

  getCategories() {
    this.superAdminService.getMegaCategories()
      .subscribe((res: IResponse) => {
        console.log('Response ', res);
        this.categories = [];
        const all = res.data;
        all.forEach((category) => {
          const subCat = JSON.parse(JSON.stringify(category.subcategory));
          this.categories.push(category.category, ...subCat);
        });
        console.log('this.categories ', this.categories);
      }, error => {      });
  }
  getCoupons(product) {
    this.alertService.info('Fetching coupons!');
    this.superAdminService.getProductCoupon(product.product_id)
      .subscribe((res: IResponse) => {
        console.log('Response ', res);
        this.coupons = res.data;
        if (this.coupons.length) {
          this.utilService.openModal('viewAttachCoupon');
        } else {
          this.alertService.info('No coupon attached!');
        }
      }, error => {      });
  }
  removeCoupon(coupon) {
    this.superAdminService.deleteProductCoupon(coupon.productcoupon_id)
      .subscribe((res: IResponse) => {
        console.log('Response ', res);
       this.utilService.closeModal('viewAttachCoupon');
      }, error => {      });
  }
  public getUploadEvent(e) {
    console.log('Event ', e);
    this.product.imageupload = [];
    this.product.imageupload.push(e);
  }
  public updateField(id) {
    setTimeout(() => {
      const val = $('#' + id).val();
      this.product[id] = val;
    }, 1000);
  }

  public startLoading(event) {
    if (event === 'showLoading') {
      setTimeout(() => {
        this.loaders.loadingImg = false;
      }, 3000);
      this.loaders.loadingImg = true;
    }
  }
  public removeImageAndRestorePicker(position) {
    console.log('Remove');
    // this.product.imageupload.splice(position, 1);
    this.product._image = null;
  }

}
