import { Component, OnInit } from '@angular/core';
import {UserService} from "../../services/apiCalls/userService/user.service";
import {UtilService} from "../../services/utilService/util.service";
import {IResponse} from "../../interfaces/iresponse";
import {environment as ENV} from '../../../environments/environment';

@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.css']
})
export class TransactionComponent implements OnInit {
  breadCrumb: any;
  loadingData: boolean = false;
  currentUser: any = null;
  transactions: any[] = [];
  constructor(private utilService: UtilService, private userService: UserService) {
    this.currentUser = this.utilService.getAuthUser();
  }

  ngOnInit() {
    this.breadCrumb  = {
      name: 'Transaction report',
      parent: 'Reports',
      subLink: null
    };
    this.getTransactionReport();
  }


  getTransactionReport() {

    /*($('#transaction-reports')as any).DataTable( {
      "processing": true,
      "serverSide": true,
      "ajax": $.ajax
      ({
        type: "GET",
        url: "https://riders.upltest.com.ng/api/v1/transactions",
        dataType: 'json',
        headers: {
          "Authorization": sessionStorage.getItem(ENV.TOKEN),
          'Content-Type':  'application/json'
        },
        data: '{ "comment" }',
        success: function (){
          alert('Thanks for your comment!');
        }
      })
    } );*/
    this.loadingData = true;
    console.log('urrent ', this.currentUser);
    this.userService.getTransactions()
      .subscribe((res: IResponse) => {
        console.log('Response ', res);
        this.transactions = res.data;
        this.utilService.startDatatable('transaction-reports');
        this.loadingData = false;
      }, error => {
        console.log('Error ', error);
        this.utilService.startDatatable('transaction-reports');
        this.loadingData = false;
      });
  }
}
