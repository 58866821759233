import { Component, OnInit } from '@angular/core';
import {UtilService} from '../../services/utilService/util.service';
import {NavigatorService} from '../../services/navigatorService/navigator.service';
import {BootstrapNotifyService} from '../../services/bootstrap-notify/bootstrap-notify.service';
import {IResponse} from '../../interfaces/iresponse';
import {SuperAdminService} from '../../services/apiCalls/superAdminService/super-admin.service';

@Component({
  selector: 'app-sales-by-customer',
  templateUrl: './sales-by-customer.component.html',
  styleUrls: ['./sales-by-customer.component.css']
})
export class SalesByCustomerComponent implements OnInit {
  breadCrumb: any;
  salesProducts = [];
  currentUser = null;
  loading = false;
  products = [];
  reportProducts = [];
  constructor(private utilService: UtilService,
              private navigatorService: NavigatorService,
              private userService: SuperAdminService,
              private superAdminService: SuperAdminService,
              private alertService: BootstrapNotifyService) { }

  ngOnInit() {
    this.getSalesCustomers();
    this.currentUser = this.utilService.getAuthUser();
    this.breadCrumb  = {
      name: 'Sales by Customer',
      parent: 'Report',
      subLink: null
    };
  }
  getSalesCustomers() {
    this.loading = true;
    this.userService.getSalesByCustomers()
      .subscribe((res: IResponse) => {
        this.salesProducts = res.data;
        console.log('Sales Product ', res);
        this.utilService.startDatatable('sales-by-customer');
        this.alertService.success(res.message || 'Sales by customers listed successfully');
        this.loading = false;
      }, error => {
        console.log({error});
        this.loading = false;
        this.utilService.startDatatable('sales-by-customer');
        this.alertService.error(error.error.message || 'Unable to list products by sales');
      });
  }
  gotoURL(url) {
    this.navigatorService.navigateUrl(url);
  }
  viewProducts(report) {
    this.reportProducts = report.orderproducts;
    this.utilService.openModal('viewDetails');
  }
  filterReport(e) {
    // this.getSalesCustomers(e.product.product_id);
  }
  /*public getProducts() {
    this.superAdminService.getProducts().subscribe((res: IResponse) => {
      console.log('Res ', res);
      this.products = res.data;
      this.getSalesCustomers(this.products[0].product.product_id);
    }, error => {
      console.log('Error ', error);
      this.alertService.error(error.error.message || 'Unable to list products');
    } );
  }*/
}
