import { Component, OnInit } from '@angular/core';
import {UtilService} from '../../services/utilService/util.service';
import {SuperAdminService} from '../../services/apiCalls/superAdminService/super-admin.service';
import { environment as ENV } from '../../../environments/environment';
import {IResponse} from '../../interfaces/iresponse';
import {BootstrapNotifyService} from '../../services/bootstrap-notify/bootstrap-notify.service';

@Component({
  selector: 'app-vat-management',
  templateUrl: './vat-management.component.html',
  styleUrls: ['./vat-management.component.css']
})
export class VatManagementComponent implements OnInit {
  breadCrumb: any;
  public EMAIL_VALIDATION: any =  ENV.EMAIL_VALIDATION;
  loaders = {
    saving: false
  };
  allStates: any [] = [];
  newVAT =  {
    taxclass_name: null,
    taxclass_rate: null,
    taxclass_type: 'flat'
  };
  public activeVAT = null;
  public VATS: any[] = [];
  public loadingData = false;
  public modal = {
    title: 'Create VAT',
    btnText: 'SAVE'
  };
  showData = true;
  constructor(private utilService: UtilService,
              private alertService: BootstrapNotifyService,
              private superAdminService: SuperAdminService) { }

  ngOnInit() {
    this.breadCrumb  = {
      name: 'Manage VAT',
      parent: 'Home',
      subLink: null
    };
    this.getVATS();
  }
  saveVAT() {
    this.loaders.saving = true;
    if(this.activeVAT) {
      this.updateVAT();
    } else {
      if(this.newVAT.taxclass_name && this.newVAT.taxclass_rate && this.newVAT.taxclass_type) {
        this.superAdminService.createVAT(this.newVAT).subscribe((res) => {
          console.log('Res ', res);
          this.getVATS();
          this.loaders.saving = false;
          this.utilService.closeModal('vatModal');
          this.alertService.success(res.message || 'VAT created successfully!', 'right');
        }, error => {
          console.log('Error ', error);
          this.alertService.error(error.error.message || 'Unable to create vat!');
          this.loaders.saving = false;
        });
      } else {
        this.alertService.error('Unable to create vat!');
        this.loaders.saving = false;
      }
    }

  }
  openDialog(id) {
    this.modal = {
      title: 'Create VAT',
      btnText: 'SAVE'
    };
    this.activeVAT = null;
    this.newVAT =  {
      taxclass_name: null,
      taxclass_rate: null,
      taxclass_type: 'flat'
    };
    this.utilService.openModal(id);
  }
  getVATS() {
    this.showData = false;
    this.loadingData = true;
    this.superAdminService.getVATS()
      .subscribe((res: IResponse) => {
        console.log('Response ', res);
        this.VATS = res.data;
        this.utilService.startDatatable('list-vats');
        this.loadingData = false;
        this.showData = true;
      }, error => {
        console.log('Error ', error);
        this.utilService.startDatatable('list-vats');
        this.loadingData = false;
        this.showData = true;
      });
  }

  public triggerEdit(vat){
    this.activeVAT = JSON.parse(JSON.stringify(vat));
    this.newVAT =  {
      taxclass_name: null,
      taxclass_rate: null,
      taxclass_type: 'flat'
    };
    this.utilService.openModal('vatModal');
    this.newVAT = this.activeVAT;
    this.modal = {
      title: 'Update VAT',
      btnText: 'UPDATE'
    };
  }

  public updateVAT() {
    if(this.newVAT.taxclass_type && this.newVAT.taxclass_rate && this.newVAT.taxclass_name) {
      this.superAdminService.updateVAT({...this.newVAT, taxclass_id: this.activeVAT.id}).subscribe((res) => {
        console.log('Res ', res);
        this.getVATS();
        this.utilService.closeModal('vatModal');
        this.loaders.saving = false;
        this.alertService.success(res.message || 'VAT updated successfully!', 'right');
      }, error => {
        console.log('Error ', error);
        this.alertService.error(error.error.message || 'Unable to update vat!');
        this.loaders.saving = false;

      });
    } else {
      this.alertService.error('Unable to update vat!');
      this.loaders.saving = false;

    }
  }
}
