import {AfterViewInit, Component, OnInit} from '@angular/core';
import {UserService} from '../../../../services/apiCalls/userService/user.service';
import {AuthService} from '../../../../services/authService/auth.service';
import {Router} from '@angular/router';
import {UtilService} from '../../../../services/utilService/util.service';
import {CacheService} from '../../../../services/cacheService/cache.service';
import {DecryptService} from '../../../../services/decryptService/decrypt.service';
import {EventsService} from '../../../../services/eventServices/event.service';
import {BootstrapNotifyService} from '../../../../services/bootstrap-notify/bootstrap-notify.service';
import {NavigatorService} from '../../../../services/navigatorService/navigator.service';
import {IResponse} from '../../../../interfaces/iresponse';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit {

  public currentUser: any;
  public userRole = null;
  public wallet: any;
  constructor(private userService: UserService,
              private authService: AuthService,
              private utilService: UtilService,
              private alertService: BootstrapNotifyService,
              private eventService: EventsService,
              private navigateService: NavigatorService,
              private cacheService: CacheService,
              private decryptService: DecryptService,
              private router: Router) {
    this.wallet = 0.0;
    this.eventService.on('RELOAD_HISTORY', () => {
      // this.getWallet(this.currentUser.id);
    });
  }

  ngOnInit() {
    this.getAuthUser();
    this.userRole = this.utilService.getAuthUserRole().toLowerCase();
  }
  ngAfterViewInit(): void {
    $('.btn-toggle-offcanvas').on('click', function() {
      console.log('Toogle OffCanvas 3');
      $('body').toggleClass('offcanvas-active');
    });

    $('#main-content').on('click', function() {
      $('body').removeClass('offcanvas-active');
    });
  }

  public getAuthUser() {
    this.currentUser = this.utilService.getAuthUser();
    console.info('Current User', this.currentUser);
    this.getWallet(this.currentUser.id);
  }
  public getWallet(userId) {
    this.userService.getWallet(userId).subscribe((res: IResponse) => {
      console.log('Wallet Data ', res);
      this.wallet = res.data.balance || 0;
    }, error => {    });
  }
  public logOut() {
    this.userService.logOut().subscribe(() => {
      this.authService.logOut();
    });
    this.authService.logOut();
    this.router.navigate(['/']);
  }
  public openProfile() {
    this.navigateService.navigateUrl(`/${this.currentUser.role.toLowerCase()}/manage-profile`);
  }
  public toggleOffCanvas() {
    console.log('Toogle OffCanvas');
    $('body').toggleClass('offcanvas-active');
  }
  public triggerFundWallet() {
    this.eventService.broadcast('FUND_WALLET_NOW');;
  }
  public searchInitiator() {
    this.alertService.info('Global searching is not implemented yet!');
  }
  public checkPrivilege(roles) {
    return this.utilService.checkPrivilegeNow(roles);
  }
}
