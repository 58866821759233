import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { Croppie } from 'croppie/croppie';

import {isNullOrUndefined} from 'util';
import {UploadService} from '../../../services/uploadService/upload.service';
import { Upload } from '../../../models/upload';
import {BootstrapNotifyService} from '../../../services/bootstrap-notify/bootstrap-notify.service';

@Component({
  selector: 'app-croppie',
  templateUrl: './croppie.component.html',
  styleUrls: ['./croppie.component.css']
})
export class CroppieComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() croppieData: any;
  @Output() updateUI: EventEmitter<any> = new EventEmitter();
  @Output() showLoad: EventEmitter<any> = new EventEmitter();
  vanilla: any;
  selectedFiles: any;
  imageChanged: boolean;
  imageUploaded: boolean;
  activateCroppie: boolean;
  uploadElement: any;
  fileKeeper: any;
  interval: any;
  imageLoader: any;
  constructor(private uploadService: UploadService,
              private notify: BootstrapNotifyService,
              // private firebaseDeleteService: DeleteService
  ) {
    this.imageChanged = false;
    this.imageUploaded = false;
    this.activateCroppie = false;
  }
  ngOnInit() {
    // this.initFileUpload();
    // console.log('SHow ', this.croppieData);
    // $('#displayImage .cr-boundary').
  this.croppieData.config.profile = this.croppieData.config.profile || false;
  }
  ngAfterViewInit() {
  }
  startInitProcess() {
    this.activateCroppie = true;
    setTimeout(() => {
      this.initCroppie();
      $('.cr-slider').addClass('makeWhite');
      $('.makeWhite').attr('style', 'background-color: #ffffff!important');
    }, 100);
  }
  rotateLeft() {
    this.vanilla.rotate(90);
    this.imageChanged = true;
  }

  rotateRight() {
    this.vanilla.rotate(-90);
    this.imageChanged = true;
  }
  result() {
    this.showLoad.emit('showLoading');
    this.vanilla.result('canvas', 'original', 'png', 1).then((image) => {
      // console.log('FileList ', this.selectedFiles, image);
      this.croppieData.data[this.croppieData.key] = image;
      this.updateUI.emit(image);
    });
  }
  getCroppieImage(quality: number, numCalls: number): Promise<any> {
    return this.vanilla.result({
      type: 'blob',
      size: {
        width: 500,
        height: 500
      },
      format: 'jpeg',
      quality: quality,
      circle: false
    })
      .then((imageDataBlob) => {
        // If image size is still too large just call result again with lower quality
        if (imageDataBlob.size > 500000 && numCalls < 3) {
          quality = quality - 0.1;
          numCalls++;
          return this.getCroppieImage(quality, numCalls);
        } else {
          return imageDataBlob;
        }
      });
  }
  initCroppie() {
    console.log('PORT ', this.croppieData.config.vwidth, this.croppieData.config.vheight);
    const el = document.getElementById(this.croppieData.id);
    const Options = {
      viewport: { width: this.croppieData.config.vwidth, height: this.croppieData.config.vheight,
        type: this.croppieData.config.type || 'square' },
      boundary: { width: this.croppieData.config.fwidth, height: this.croppieData.config.fheight },
      showZoomer: true,
      enableOrientation: true,
      enableResize: this.croppieData.config.enableResize,
      mouseWheelZoom: 'ctrl',
  };
    this.vanilla = new Croppie(el, Options);
    this.vanilla.bind({
      url: this.imageLoader,
      orientation: 1
    });

  }

  initFileUpload() {
    this.uploadElement = document.getElementById('vanilla-demo');
    this.uploadElement.addEventListener('change', this.onFileUpload.bind(this));
  }

  onFileUpload() {
    if (this.uploadElement.files && this.uploadElement.files.length > 0) {
      this.imageChanged = true;

      const file = this.uploadElement.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = ((event: any) => {
        this.vanilla.bind({
          url: event.target.result
        });
      });
    }
  }
  public processSelectedFile(event) {
    this.imageUploaded = false;
     const selectedFiles =  event.target.files;
    const reader = new FileReader();
    const file = this.fileKeeper = selectedFiles[0];
    if (!isNullOrUndefined(file)) {
      reader.onloadend = () => {
        this.imageLoader = reader.result;
        this.imageUploaded = true;
        this.startInitProcess();
      };
      reader.readAsDataURL(file);
    }
  }
  public removeImageAndRestorePicker() {
    console.log('Remove');
  }
  ngOnDestroy() {
    // clearInterval(this.interval);
    // this.uploadElement.removeEventListener('change', this.onFileUpload);
    // this.croppieData = null;
  }
}
