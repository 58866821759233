import {Component, OnInit} from '@angular/core';
import { environment as ENV } from '../../../environments/environment';
import {BootstrapNotifyService} from '../../services/bootstrap-notify/bootstrap-notify.service';
import {UserService} from '../../services/apiCalls/userService/user.service';
import {IResponse} from '../../interfaces/iresponse';
import {NavigatorService} from '../../services/navigatorService/navigator.service';
import * as JWT_DECODE from 'jwt-decode';
import {CacheService} from '../../services/cacheService/cache.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from "../../services/authService/auth.service";
import {SidebarComponent} from "../../shared/layout/dashbord/sidebar/sidebar.component";
import {EventsService} from "../../services/eventServices/event.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public EMAIL_VALIDATION: any =  ENV.EMAIL_VALIDATION;
  public credentials = {
    email: null,
    password: null
  };
  public userDetails: any;
  loaders = {
    login: false,
    showResetLink: false
  };
  constructor(private bootstrapNotify: BootstrapNotifyService,
              private userService: UserService,
              private cacheService: CacheService,
              private navigateService: NavigatorService,
              private router: Router,
              private activeRoute: ActivatedRoute,
              private eventService: EventsService,
              private authService: AuthService) {
    this.authService.logOut();
  }
  ngOnInit(): void {
    this.userDetails = null;
  }
  public loginProcess() {
    this.loaders.login = true;
    this.cacheService.deleteSession(ENV.TOKEN);
    this.cacheService.deleteStorage(ENV.TOKEN);
    if (!this.credentials.email || !this.credentials.password) {
      this.bootstrapNotify.info('Provide login details!');
      this.loaders.login = false;
    } else {
      this.userService.auth(this.credentials).subscribe((response: IResponse) => {
        console.log('Response', response);
        this.loaders.login = false;
        this.eventService.broadcast('NEW_LOGIN');
        this.bootstrapNotify.success('Login successful!');
        this.authService.handleRedirect(response);
      }, error => {
        this.bootstrapNotify.error(error.error.message || 'Unable to login', 'right');
        this.loaders.login = false;
        console.info('Error => ', error);
      });
    }
  }
}
