import { Component, OnInit } from '@angular/core';
import {SuperAdminService} from "../../services/apiCalls/superAdminService/super-admin.service";
import {IResponse} from "../../interfaces/iresponse";

@Component({
  selector: 'app-super-dashboard',
  templateUrl: './super-dashboard.component.html',
  styleUrls: ['./super-dashboard.component.css']
})
export class SuperDashboardComponent implements OnInit {
  breadCrumb: any;
  public dashboard: any = null;
  constructor(private superAdminService: SuperAdminService) { }

  ngOnInit() {
    this.breadCrumb  = {
      name: 'Dashboard',
      parent: 'Home',
      subLink: null
    };
    this.setDashboard();
  }

  public setDashboard() {
    this.superAdminService.getDashboard()
      .subscribe((res: IResponse) => {
        console.log('Response ', res);
        this.dashboard = res.data;
      }, error => {
        console.log('Error ', error);
      });
  }

}
