import { Component, OnInit } from '@angular/core';
import {UtilService} from '../../services/utilService/util.service';
import {SuperAdminService} from '../../services/apiCalls/superAdminService/super-admin.service';
import {IResponse} from '../../interfaces/iresponse';
import {UserService} from '../../services/apiCalls/userService/user.service';
import {BootstrapNotifyService} from '../../services/bootstrap-notify/bootstrap-notify.service';

@Component({
  selector: 'app-onsales',
  templateUrl: './onsales.component.html',
  styleUrls: ['./onsales.component.css']
})
export class OnsalesComponent implements OnInit {
  loaders = {
    saving: false,
    onSaleProducts: false
  };
  activeProduct = null;
  loadingData = false;
  changeOccured = true;
  public modal = {
    title: 'Create OnSales Product',
    btnText: 'SAVE'
  };
  products = [];
  onSaleProducts = [];
  selectedProduct = null;
  constructor(private utilService: UtilService,
              private userService: UserService,
              private alertService: BootstrapNotifyService,
              private bootstrapNotify: BootstrapNotifyService,
              private superAdminService: SuperAdminService) {
    this.getProducts();
  }

  ngOnInit() {
    this.getOnSalesProducts();
  }

  openDialog(id) {
    this.modal = {
      title: 'Create OnSales Product',
      btnText: 'SAVE'
    };
    this.activeProduct = null;
    this.utilService.openModal(id);
  }
  getOnSalesProducts() {
    this.loadingData = true;
    this.changeOccured = false;
    this.superAdminService.getOnSalesProducts()
      .subscribe((res: IResponse) => {
        console.log('Response ', res);
        this.onSaleProducts = res.data;
        this.utilService.startDatatable('onsale-products-list');
        this.loadingData = false;
        this.changeOccured = true;
      }, error => {
        this.utilService.startDatatable('onsale-products-list');
        this.loadingData = false;
        this.changeOccured = true;
        console.log('Error ', error);
      });
  }

  public deleteOnSalesProduct(product) {
    this.superAdminService.deleteOnSalesProduct({
      product_id: [
        product.product_id
      ]
    }).subscribe((res: IResponse) => {
      console.log('Res ', res);
      this.getOnSalesProducts();
    }, error => {
      console.log('Errro ', error);
    });
  }

  getProducts() {
    this.superAdminService.getProducts()
      .subscribe((res: IResponse) => {
        console.log('Response ', res);
        this.products = res.data;
      }, error => {
        console.log('Error ', error);
      });
  }
  saveOnSales() {
    if (this.selectedProduct.length) {
      this.loaders.saving = true;
      const productIds = {product_id: this.selectedProduct};
      this.superAdminService.createOnSalesProducts(productIds)
        .subscribe((res: IResponse) => {
          this.loaders.saving = false;
          this.getOnSalesProducts();
          this.utilService.closeModal('onSalesProduct');
          this.alertService.success(res.message || 'OnSales product added');
        }, error => {
          console.log({error});
          this.loaders.saving = false;
        });
    } else {
      this.alertService.error('No product selected');
    }
  }
}
