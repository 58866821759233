import { Component, OnInit } from '@angular/core';
import {IResponse} from "../../../interfaces/iresponse";
import {SuperAdminService} from "../../../services/apiCalls/superAdminService/super-admin.service";
import {UtilService} from "../../../services/utilService/util.service";
import {BootstrapNotifyService} from "../../../services/bootstrap-notify/bootstrap-notify.service";
import {isNullOrUndefined} from "util";

@Component({
  selector: 'app-manage-book-cover',
  templateUrl: './manage-book-cover.component.html',
  styleUrls: ['./manage-book-cover.component.css']
})
export class ManageBookCoverComponent implements OnInit {
  breadCrumb: any;
  bookCovers = [];
  vendors = [];

  loaders = {
    bookCover: false,
    saving: false
  };
  bookCoverModal = {
    title: 'Create BookCover',
    btnText: 'SAVE',
  };

  bookCover = {
    bookcover_name: null
  };
  activeBookCover = null;
  constructor(private superAdminService: SuperAdminService,
              private utilService: UtilService,
              private alertService: BootstrapNotifyService) {

  }

  ngOnInit() {
    this.breadCrumb  = {
      name: 'Manage BookCovers',
      parent: 'Configuration',
      subLink: null
    };
    this.getBookCovers();
  }

  public getBookCovers() {
    this.loaders.bookCover = true;
    this.superAdminService.getBookCovers().subscribe((res: IResponse) => {
      console.log('Res ', res);
      this.loaders.bookCover = false;
      this.bookCovers = res.data;
      this.utilService.responsiveTable('book-cover-list', false);
    }, error => {
      console.log('Error ', error);
      this.loaders.bookCover = false;
      this.alertService.error(error.error.message || 'Unable to list book covers');
      this.utilService.responsiveTable('book-cover-list', false);
    } );
  }


  openDialog(id, section) {
    switch (section) {
      case 'bookCover': {
        this.bookCoverModal = {
          title: 'Create BookCover',
          btnText: 'SAVE'
        };
        this.activeBookCover = null;
        this.resetBookCover();
        this.utilService.openModal('createBookCover');
        break;
      }
    }
  }

  resetBookCover() {
    this.bookCover = {
      bookcover_name: null
    };
  }

  public processSelectedFile(event, object) {
    const selectedFiles =  event.target.files;
    const reader = new FileReader();
    const file = selectedFiles[0];
    if (!isNullOrUndefined(file)) {
      reader.onloadend = () => {
        object.imageupload = reader.result;
      };
      reader.readAsDataURL(file);
    }
  }

  createBookCover() {
    console.log('Create BookCovers', this.bookCover);
    if (!this.bookCover.bookcover_name) {
      return this.alertService.error('Book cover name is required');
    }else if (this.activeBookCover) {
      this.loaders.saving = true;
      this.updateBookCover();
    } else {
      this.loaders.saving = true;
      this.superAdminService.createCover(this.bookCover).subscribe((res: IResponse) => {
        this.getBookCovers();
        this.alertService.success(res.message || 'Book cover created successfully!');
        this.loaders.saving = false;
        this.utilService.closeModal('createBookCover');
        this.resetBookCover();
      }, error => {
        this.loaders.saving = false;
        this.alertService.error(error.error.message || 'Unable to create bookCover!');
        console.log({error});
      });
    }
  }

  updateBookCover() {
    this.superAdminService.updateCover(this.activeBookCover).subscribe((res: IResponse) => {
      this.getBookCovers();
      this.alertService.success(res.message || 'BookCover updated successfully!');
      this.loaders.saving = false;
      this.utilService.closeModal('createBookCover');
      this.resetBookCover();
    }, error => {
      this.loaders.saving = false;
      this.alertService.error(error.error.message || 'Unable to update bookCover!');
      console.log({error});
    });
  }
  triggerEdit(data, id) {
    switch (id) {
      case 'bookCover': {
        this.activeBookCover = JSON.parse(JSON.stringify(data));
        this.resetBookCover();
        this.bookCover = this.activeBookCover;
        this.bookCoverModal = {
          title: 'Update Book Cover',
          btnText: 'UPDATE'
        };
        this.utilService.openModal('createBookCover');
        break;
      }
    }
  }
  triggerDelete(data, id) {
    switch (id) {
      case 'bookCover': {
        this.utilService.confirmAction(() => {
          this.superAdminService.deleteCover(data.id)
            .subscribe((res: IResponse) => {
              console.log('Res ', res);
              this.getBookCovers();
            }, error => {
              console.log('Errro ', error);
            });
        });
        break;
      }
    }
  }

}
