import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-groups-management',
  templateUrl: './user-groups-management.component.html',
  styleUrls: ['./user-groups-management.component.css']
})
export class UserGroupsManagementComponent implements OnInit {
  breadCrumb: any;
  constructor() { }

  ngOnInit() {
    this.breadCrumb  = {
      name: 'User-Groups',
      parent: 'Settings',
      subLink: null
    };
  }

}
