import { Component, OnInit } from '@angular/core';
import {UtilService} from '../../services/utilService/util.service';
import {SuperAdminService} from '../../services/apiCalls/superAdminService/super-admin.service';
import {IResponse} from '../../interfaces/iresponse';
import {UserService} from '../../services/apiCalls/userService/user.service';
import {BootstrapNotifyService} from '../../services/bootstrap-notify/bootstrap-notify.service';
import {isNullOrUndefined} from 'util';

@Component({
  selector: 'app-featured',
  templateUrl: './featured.component.html',
  styleUrls: ['./featured.component.css']
})
export class FeaturedComponent implements OnInit {
  loaders = {
    saving: false,
    featuredProducts: false
  };
  activeProduct = null;
  loadingData = false;
  changeOccured = true;
  public modal = {
    title: 'Create Featured Product',
    btnText: 'SAVE'
  };
  products = [];
  featuredProducts = [];
  selectedProduct = null;
  constructor(private utilService: UtilService,
              private userService: UserService,
              private alertService: BootstrapNotifyService,
              private bootstrapNotify: BootstrapNotifyService,
              private superAdminService: SuperAdminService) {
    this.getProducts();
  }

  ngOnInit() {
    this.getFeaturedProducts();
  }

  openDialog(id) {
    this.modal = {
      title: 'Create Featured Product',
      btnText: 'SAVE'
    };
    this.activeProduct = null;
    this.utilService.openModal(id);
  }
  getFeaturedProducts() {
    this.loadingData = true;
    this.changeOccured = false;
    this.superAdminService.getFeaturedProducts()
      .subscribe((res: IResponse) => {
        console.log('Response ', res);
        this.featuredProducts = res.data;
        this.utilService.startDatatable('products-list');
        this.loadingData = false;
        this.changeOccured = true;
      }, error => {
        this.utilService.startDatatable('products-list');
        this.loadingData = false;
        this.changeOccured = true;
        console.log('Error ', error);
      });
  }

  public deleteFeaturedProduct(product) {
    this.superAdminService.deleteFeaturedProduct({
      product_id: [
        product.product_id
      ]
    }).subscribe((res: IResponse) => {
        console.log('Res ', res);
        this.getFeaturedProducts();
      }, error => {
        console.log('Errro ', error);
      });
  }

  getProducts() {
    this.superAdminService.getProducts()
      .subscribe((res: IResponse) => {
        console.log('Response ', res);
        this.products = res.data;
      }, error => {
        console.log('Error ', error);
      });
  }
  saveFeatured() {
    if (this.selectedProduct.length) {
      this.loaders.saving = true;
      const productIds = {product_id: this.selectedProduct};
      this.superAdminService.createFeaturedProducts(productIds)
        .subscribe((res: IResponse) => {
        this.loaders.saving = false;
        this.getFeaturedProducts();
        this.utilService.closeModal('featuredProduct');
        this.alertService.success(res.message || 'Featured product added');
      }, error => {
        console.log({error});
        this.loaders.saving = false;
      });
    } else {
      this.alertService.error('No product selected');
    }
  }
}
